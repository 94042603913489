import React, { useContext } from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { Box, Flex, Text } from "theme-ui"
import { graphql, navigate, useStaticQuery } from "gatsby"

import getDisplayItems from "../../../utils/get-display-items"
import {
  formatCartDiscount,
  formatCartGiftCard,
  formatCartShippingTotal,
  formatCartSubtotal,
  formatCartTotal,
} from "../../../utils/prices"
import { trackCheckoutInitialized } from "../../../services/analytics"
import Typography from "../../ui/Typography"
import Button from "../button"
import CodeInput, { CodeTypes } from "../code-input"
import FreeShippingProgress from "../../Header/FreeShippingProgress"
import { MobileContainer } from "../shared"
import CartFooter from "./cart-footer"
import CartItem from "./cart-item"
import Cross from "../../../assets/svg/dismiss.svg"
import { InterfaceContext } from "../../../context/InterfaceContext"
import { useTranslationContext } from "../../../context/TranslationContext"
import Tooltip from "../tooltip"
import TileSection from "../tile-section"

const Small = styled.span`
  ${Typography.Small}
`

const Container = styled(Flex)`
  flex-direction: column;

  flex: 1;
  height: 100%;

  width: 100%;

  margin-left: auto;
  margin-right: auto;
`

const CartItemContainer = styled(Box)`
  flex: 1;
  overflow-y: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;

  width: 100%;
`

const CartSummaryContainer = styled(Flex)`
  flex: none;
  flex-direction: column;
  padding-top: 1rem;
  align-items: center;

  .mute {
    color: ${(props) => props.theme.colors.dark};
  }
`

const CartRow = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: 0;
  }

  &.tax-note {
    margin: 1rem 0;
    justify-content: flex-end;
    text-align: right;

    .note {
      width: 70%;
    }
  }
`
const Header = styled(Flex)`
  padding: 0 16px;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 460px;
  }
`

const Wrapper = styled(Flex)`
  > div:not(:last-of-type) {
    padding: 0 16px;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    > div:not(:last-of-type) {
      padding: 0px;
      margin-left: auto;
      margin-right: auto;
      width: 460px;
      padding-right: 10px;
    }
  }
`

const recommendedItemsQuery = graphql`
  query {
    recommended: allContentfulTileSection(
      filter: { title: { eq: "Cart Recommendations" } }
    ) {
      nodes {
        node_locale
        ...TileSection
      }
    }
  }
`

const CartSummary = ({
  cart,
  freeShippingLimit,
  giftCardError,
  couponCodeError,
  onAddCouponCode,
  onRemoveCouponCode,
  onUpdateQuantity,
  onAddGiftCardCode,
  onRemoveGiftCard,
  onRemoveLine,
}) => {
  const { t } = useTranslation()
  const items = getDisplayItems(cart).sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at)
  })

  const { translationContext } = useTranslationContext()

  const { hideDrawers } = useContext(InterfaceContext)

  const { recommended } = useStaticQuery(recommendedItemsQuery)

  const recommendedItems = recommended.nodes.find(
    (node) => node.node_locale === translationContext.locale
  )

  const handleVatText = () => {
    if (!cart) return

    if (cart.shipping_address?.country_code?.toLowerCase() === "gb") {
      return (
        <div className="total">
          <div />
          <Small>{t("gb_vat")}</Small>
        </div>
      )
    } else if (parseFloat(cart.region.tax_rate) === 0) {
      return (
        <div className="total">
          <div />
          <Small>{t("excl_vat")}</Small>
        </div>
      )
    }
    return null
  }

  const codeInputStyle = {
    flex: "none",
    borderTop: "muted",
    paddingTop: "15px",
    paddingBottom: "15px",
  }

  return (
    <Container>
      <Header sx={{ color: "dark" }}>
        <Text
          sx={{
            fontSize: "21px",
            flex: "1",
          }}
        >
          {t("cart").charAt(0).toUpperCase() + t("cart").slice(1)}
        </Text>
        <MobileContainer>
          <Cross onClick={hideDrawers} />
        </MobileContainer>
      </Header>

      {items && items.length > 0 ? (
        <Wrapper sx={{ height: "100%", flex: "1", flexDirection: "column" }}>
          {freeShippingLimit > 0 && (
            <FreeShippingProgress
              pt={"0.5rem"}
              cart={cart}
              limit={freeShippingLimit}
            />
          )}
          <CartItemContainer sx={{ height: "100%", flexDirection: "column" }}>
            <Box pb={"15px"} mt="1rem">
              {items.map((i) => (
                <CartItem
                  key={i.id}
                  isCheckout
                  isBundle={i.is_bundle}
                  item={i}
                  thumbnail={i.thumbnail}
                  name={i.title}
                  itemPrice={i.unit_price}
                  cart={cart}
                  allowEditting={true}
                  onUpdateQuantity={onUpdateQuantity}
                  onRemoveLine={onRemoveLine}
                />
              ))}
            </Box>
            <CodeInput
              sx={codeInputStyle}
              discounts={cart.gift_cards}
              type={CodeTypes.GIFT_CARD}
              error={giftCardError}
              onSubmit={onAddGiftCardCode}
              onDelete={onRemoveGiftCard}
            />
            <CodeInput
              sx={codeInputStyle}
              discounts={cart.discounts}
              type={CodeTypes.DISCOUNT}
              error={couponCodeError}
              onSubmit={onAddCouponCode}
              onDelete={onRemoveCouponCode}
            />

            {recommendedItems && (
              <Flex
                sx={{ flexDirection: "column", color: "dark" }}
                onClick={hideDrawers}
              >
                <Text sx={{ pb: "15px" }}>{t("you_might_also_like")}</Text>
                <TileSection
                  {...recommendedItems}
                  hideTitle={true}
                  tileSx={{ ".tile-title": { paddingX: 0 } }}
                  tiles={
                    (recommendedItems.tiles = recommendedItems.tiles.map(
                      (item) => {
                        return {
                          refLocation: "cart",
                          ...item,
                        }
                      }
                    ))
                  }
                />
              </Flex>
            )}
          </CartItemContainer>
          <Flex
            sx={{ flex: "none", flexDirection: "column" }}
            mb={["0px", "24px"]}
          >
            <CartSummaryContainer
              sx={{
                fontSize: "15px",
                width: "100%",
                borderTop: "1px solid #4545451A",
                backgroundColor: ["white", "almostWhite"],
              }}
            >
              <Flex
                px={["16px", "16px", "0px"]}
                sx={{
                  flexDirection: "column",
                  width: ["100%", "100%", "450px"],
                }}
              >
                <CartRow sx={{ fontSize: "15px" }}>
                  <Text>
                    {parseFloat(cart.region.tax_rate) === 0
                      ? t("subtotal")
                      : t("subtotal_incl_tax")}
                  </Text>
                  <Text sx={{ fontWeight: "bold" }} className="mute">
                    {formatCartSubtotal(cart)}
                  </Text>
                </CartRow>
                {!!cart.gift_card_total && (
                  <CartRow>
                    <div>{t("gift_card")}</div>
                    <div className="mute">-{formatCartGiftCard(cart)}</div>
                  </CartRow>
                )}
                {!!cart.discount_total && (
                  <CartRow>
                    <div>{t("discount")}</div>
                    <div className="mute">{formatCartDiscount(cart)}</div>
                  </CartRow>
                )}
                {cart.shipping_methods &&
                  cart.shipping_methods.map((sm, i) => (
                    <CartRow key={`cart-row-${i}`}>
                      <div>{t("shipping")}</div>
                      {sm.price === 0 ? (
                        <div className="mute">{t("free")}</div>
                      ) : (
                        <div className="mute">
                          {formatCartShippingTotal(cart)}
                        </div>
                      )}
                    </CartRow>
                  ))}
                <CartRow
                  pt={"8px"}
                  mt={"24px"}
                  sx={{ borderTop: "1px solid #4545451A", fontSize: "15px" }}
                >
                  <Flex>
                    <Text>{t("total")}</Text>
                    {cart.shipping_address &&
                      cart.shipping_address?.country_code.toLowerCase() ===
                        "us" &&
                      cart.subtotal > 80000 && (
                        <Tooltip
                          text="*"
                          popover={t("your_subtotal_exceeds", {
                            value: 800,
                            currencyCode: "USD",
                          })}
                          options={{ withIcon: false }}
                        />
                      )}
                  </Flex>
                  <Text sx={{ fontWeight: "bold" }} className="mute">
                    {formatCartTotal(cart)}
                  </Text>
                </CartRow>
                {handleVatText()}

                <CartRow sx={{ flexDirection: ["column-reverse", "row"] }}>
                  <CartFooter cart={cart} sx={{ flex: 1, flexBasis: "50%" }} />
                  <Button
                    sx={{
                      flexBasis: ["100%", "160px"],
                      height: "40px",
                      flexGrow: 1,
                    }}
                    onClick={() => {
                      hideDrawers()
                      trackCheckoutInitialized(cart)
                      navigate("/checkout")
                    }}
                  >
                    {t("go_to_checkout")}
                  </Button>
                </CartRow>
              </Flex>
            </CartSummaryContainer>
          </Flex>
        </Wrapper>
      ) : (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {t("cart_empty")}
        </Flex>
      )}
    </Container>
  )
}

export default CartSummary

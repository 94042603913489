import React, { useState } from "react"
import PropTypes from "prop-types"

import Arrow from "../../../../assets/svg/arrow-down.svg"
import {
  GalleryContainer,
  ImageLink,
  LinkContent,
  LinkBody,
  ScrollButton,
  Image,
  ImageScroller,
  ImageContainer,
  CarouselContent,
} from "./elements"
import Carousel from "../../../ui/Carousel"
import { navigate } from "gatsby"

const Gallery = ({ input, className }) => {
  const { title, cta, images, galleryLinkUrl } = input
  const [currentIndex, setCurrentIndex] = useState(0)

  const max = images.length - 1
  const handlePrev = () => {
    const newIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const handleNext = () => {
    const newIndex = currentIndex + 1 > max ? max : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const items = images.map((img) => ({
    image: img.image,
    content: <CarouselContent>{!img.hideTitle && img.title}</CarouselContent>,
    ...img,
  }))

  return (
    <div className={className}>
      <GalleryContainer showOnMobile={false} showOnDesktop>
        <ImageContainer>
          <ScrollButton
            show={currentIndex !== 0}
            className="prev"
            onClick={handlePrev}
          >
            <Arrow />
          </ScrollButton>
          <ScrollButton
            show={currentIndex !== max}
            className="next"
            onClick={handleNext}
          >
            <Arrow />
          </ScrollButton>
          <ImageScroller index={currentIndex}>
            {images.map((i) => {
              const featured = i.image
              const alternate = i.alternateImage || featured
              return (
                <ImageLink key={i.id} to={i.linkUrl}>
                  <LinkBody>
                    <Image
                      className="default"
                      image={featured.gatsbyImageData}
                      alt=""
                    />
                    <Image
                      className="hover"
                      image={alternate.gatsbyImageData}
                      alt=""
                    />
                    <LinkContent>{i.content}</LinkContent>
                  </LinkBody>
                </ImageLink>
              )
            })}
          </ImageScroller>
        </ImageContainer>
      </GalleryContainer>
      <GalleryContainer showOnMobile showOnDesktop={false}>
        <Carousel items={items} />
      </GalleryContainer>
    </div>
  )
}

Gallery.propTypes = {
  input: PropTypes.object,
}

export default Gallery

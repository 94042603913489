import React, { useState } from "react"
import { Flex } from "theme-ui"
import styled from "@emotion/styled"
import ContentModules from "../../Page/ContentModules/ContentModules"

const Container = styled(Flex)`
  flex-direction: column;

  .rich-text {
    width: 100%;
  }
`

const Header = styled(Flex)`
  font-size: 21px;
  margin-right: 32px;
  color: ${(props) => props.theme.colors.dark_30};
  margin-bottom: 32px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    `
      color: ${props.theme.colors.dark};
      border-bottom: ${props.theme.borders.dark};
    `}
`

const Tabs = ({ tabs, sx }) => {
  const [active, setActive] = useState(tabs[0])

  return (
    <Container sx={sx}>
      <Flex>
        {tabs.map((tab) => (
          <Header
            key={tab.id}
            onClick={() => setActive(tab)}
            active={active.id === tab.id}
          >
            {tab.title}
          </Header>
        ))}
      </Flex>
      <ContentModules allModules={[active]} />
    </Container>
  )
}

export default Tabs

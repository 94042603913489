import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image";

import Link from "../../../../utils/Link"
import Typography from "../../../ui/Typography"

const WIDTH = 40
const IMG_WIDTH = `${WIDTH}vw`
const IMG_HEIGHT = `${WIDTH / 1.58}vw`

export const CarouselContent = styled.div`
  ${Typography.Medium}
  flex: 1;
`

export const GalleryContainer = styled.div`
  display: flex;
  width: 100%;

  padding-bottom: 2rem;
  display: ${props =>
    props.showOnMobile === undefined || props.showOnMobile ? `flex` : `none`};

  ${props => props.theme.breakpointsLegacy.tablet} {
    display: ${props =>
      props.showOnDesktop === undefined || props.showOnDesktop
        ? `flex`
        : `none`};
  }
`

export const Image = styled(GatsbyImage)`
  width: ${IMG_WIDTH};
  height: ${IMG_HEIGHT};
`

export const ImageLink = styled(Link)`
  display: flex;
  min-width: 60vw;
  text-decoration: none;

  .hover {
    display: none;
  }

  &:hover {
    .default {
      display: none;
    }

    .hover {
      display: block;
    }
  }
`

export const LinkBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const LinkContent = styled.div`
  ${Typography.Large}
  padding: 0.8rem;
  width: ${IMG_WIDTH};
  height: 200px;

  color: ${props => props.theme.colors.darkest};
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(${IMG_HEIGHT} + 200px);
  flex: 1;
  overflow: hidden;
`

export const ImageScroller = styled.div`
  position: absolute;

  transition: left 300ms;

  left: calc((50% - ${IMG_WIDTH} / 2) - ${props => props.index} * 60vw);
  display: flex;
`

export const ScrollButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;

  [stroke*="red"] {
    stroke: ${props => props.theme.colors.darkest};
  }

  height: 25px;
  width: 25px;
  z-index: 101;

  ${props => !props.show && "display: none;"}

  svg {
    width: 25px;
    height: 25px;
    stroke: ${props => props.theme.colors.darkest};
  }

  &:hover {
    svg {
      width: 25px;
      height: 25px;
      stroke: ${props => props.theme.colors.dark};
    }
  }

  &.prev {
    transform: rotate(90deg);
    left: 200px;
  }

  &.next {
    transform: rotate(-90deg);
    right: 200px;
  }
`

import React from "react"
import styled from "@emotion/styled"
import Product from "../Product"

import { trackProductClicked } from "../../../../services/analytics"
import { handleUrlPrefix } from "../../../../utils/translations-util"

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 1px;
  grid-template-columns: repeat(
    ${(props) => handleGridSize(props.imageSizes, true)},
    1fr
  );
  grid-template-rows: 1fr;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    grid-template-columns: repeat(
      ${(props) => handleGridSize(props.imageSizes, false)},
      1fr
    );
  }
`

const handleGridSize = (imageSizes, isMobile) => {
  if (isMobile) {
    switch (imageSizes) {
      case "Medium":
      case "Small":
      case "Large":
      default:
        return 2
    }
  } else {
    switch (imageSizes) {
      case "Medium":
        return 3
      case "Large":
        return 2
      case "Small":
        return 4
      default:
        return 3
    }
  }
}

export const ProductGrid = ({
  products,
  collection,
  collectionPath,
  imageSizes,
}) => {
  return (
    <Grid imageSizes={imageSizes}>
      {products &&
        products.map((p) => (
          <Product
            onProductClick={(prod) => {
              trackProductClicked(prod, collection)
            }}
            showName={collection.productPageType === "Standalone"}
            key={p.id}
            product={p}
            collection={collection}
            collectionPath={collectionPath}
          />
        ))}
    </Grid>
  )
}

export const BundleGrid = ({ bundle, catSlug }) => {
  return (
    <Grid>
      {bundle &&
        bundle.map((collection) =>
          collection.products.map((p) => (
            <Product
              onProductClick={() => {
                trackProductClicked(p, collection)
              }}
              key={p.id}
              product={p}
              collectionPath={`/${catSlug}/${collection.slug}`}
            />
          ))
        )}
    </Grid>
  )
}

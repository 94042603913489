import styled from "@emotion/styled"
import { Link } from "gatsby"
import Typography from "../../../ui/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import { ContentfulTypes } from "../../../../utils/types"
import { PersonalizationContext } from "../../../../context/personalization-context"

const ProductTile = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  color: ${(props) => props.theme.colors.text};

  &:hover {
    color: ${(props) => props.theme.colors.textHover};
  }
`

const ProductImg = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const ProductTitle = styled.div`
  ${Typography.Base}
  height: 50px;
  padding: 0.8rem;
`

const ProductImgContainer = styled.div`
  display: flex;
  flex-direction: column;

  .hover {
    display: none;
  }

  // Don't show hover effect on touch devices
  @media (hover: hover) {
    &:hover {
      .hover {
        display: block;
      }

      .default {
        display: none;
      }
    }
  }
`

const Product = ({ product, showName, collectionPath, onProductClick }) => {
  const { gender } = useContext(PersonalizationContext)
  if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
    product = product.duvetProduct
  }

  let imagePack = product.imagePacks?.find((pack) => {
    if (product.imagePacks.length > 1 && pack.isFemale !== null) {
      return (gender === "female") === pack.isFemale
    } else {
      return true
    }
  })

  const thumbnail = imagePack?.featuredImage || {}
  const alternate = imagePack?.alternateImage || {}
  const slug = product.handle
  const color = product.color

  return (
    <ProductTile
      onClick={() => onProductClick && onProductClick(product)}
      to={`${collectionPath}/${slug}`}
      key={product.id}
    >
      <ProductImgContainer>
        <ProductImg
          className="default"
          image={thumbnail.gatsbyImageData}
          alt=""
        />
        <ProductImg
          className="hover"
          image={alternate.gatsbyImageData}
          alt=""
        />
      </ProductImgContainer>
      <ProductTitle>{showName ? product.title : color.name}</ProductTitle>
    </ProductTile>
  )
}

export default Product

import styled from "@emotion/styled"
import mq from "../../utils/mq"
import Typography from "../ui/Typography"

export const CartBag = styled.div`
  ${Typography.Medium};
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 1rem;

  .hide-on-mobile {
    display: none;
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      display: block;
    }
  }

  .circle {
    transition: width 200ms, height 200ms;
    margin-left: 0.8rem;
    border: 1px solid ${(props) => props.theme.colors.darkest};
    border-radius: calc(49% + 1px);
    margin-top: 2px;
    &.not-empty {
      padding-bottom: 1px;
      width: 19px;
      height: 19px;
    }
    &.not-empty.gt-10 {
      width: 24px;
      border-radius: 12px;
    }
    &.empty {
      width: 10px;
      height: 10px;
    }
  }

  span {
    ${Typography.Small}
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
`

export const RegionSelector = styled.div`
  display: none;
  margin-right: 2rem;
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: block;
  }
`

export const DismissCatch = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: ${(props) => (props.active ? "100%" : "0")};
  z-index: 1001;

  ${(props) =>
    props.active
      ? `
    transition: height 1ms, opacity 800ms;
  `
      : `
    transition: opacity 300ms, height 1ms ease-in-out 300ms;
  `}
`

export const NavBar = styled.div`
  position: fixed;
  background-color: ${(props) =>
    props.isTransparent && !props.isProductNavOpen
      ? "transparent"
      : props.theme.colors.almostWhite};
  color: ${(props) =>
    props.isWhite && props.isTransparent
      ? props.theme.colors.white
      : props.theme.colors.black};
  top: 0;
  right: 0;
  left: 0;
  min-height: ${(props) =>
    props.promotionalVisible
      ? props.theme.navbar.promotional
      : props.theme.navbar.height};

  display: flex;
  flex-direction: column;

  z-index: 8501;
`

export const NavItem = styled.div`
  ${Typography.Medium};
  position: relative;
  cursor: pointer;
  display: flex;
  justify-self: ${(props) => props.justifySelf};

  ${(props) =>
    props.header &&
    `
    visibility: hidden;
    flex: 1;
    padding-left: 25px;
    ${props.theme.breakpointsLegacy.tablet} {
      visibility: visible;
    }
  `}

  .hide-on-mobile {
    display: none;
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      display: block;
    }
  }

  ${(props) =>
    props.hideOnMobile
      ? mq({
          display: ["none", "none", "block", "block"],
        })
      : ``}
`

export const Mute = styled.span`
  margin-right: 8px;
  color: ${(props) => props.theme.colors.dark};
`

export const HeaderLogo = styled.img`
  height: 1rem;

  margin-right: 1rem;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    height: ${(props) => props.theme.navbar.logoHeight};
  }
`

import React, { useCallback, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Flex, Text, Box } from "theme-ui"
import { useSwipeable } from "react-swipeable"
import { useTranslation } from "react-i18next"
import AssetWrapper from "../asset-wrapper"
import Arrow from "../../../assets/svg/signifly-arrow.svg"

const CarouselContainer = styled(Flex)`
  flex: ${(props) => props?.sx?.flex ?? "1"};
  position: relative;
  overflow: hidden;

  .asset {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    display: none;
    &:nth-of-type(${(props) => props.assetIndex}) {
      display: block;
    }
  }

  svg {
    path {
      stroke: ${(props) => props.theme.colors.dark};
    }
  }

  ${(props) =>
    props.hasDarkBackground &&
    `
      color: ${props.theme.colors.white};

      svg {
        path {
          stroke: ${props.theme.colors.white};
        }
      }
    `}

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    button {
      width: unset;
    }
  }
`

const CarouselButton = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;

  padding: 10px;
  padding-bottom: 12px;

  font-size: 1rem;
  align-items: center;
  width: 15%;
  height: 100%;
  top: 0;
  z-index: 10;
  [stroke*="red"] {
    stroke: ${(props) => props.theme.colors.darkest};
  }

  svg {
    transform: rotate(90deg);
  }

  color: ${(props) => props.theme.colors.dark};

  ${(props) =>
    props.next &&
    `
    right: 0;
    justify-content: flex-end;
    svg {
      transform: rotate(270deg);
    }
    `}

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    align-items: flex-end;
  }
`

const Carousel = ({ assets = [], sx, className, ...rest }) => {
  const offset = 2

  const [assetIndex, setIndex] = useState(0)

  const textRender = assets?.[assetIndex]?.textRender

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => handlePrevious(),
    onSwipedLeft: () => handleNext(),
  })

  const { t } = useTranslation()

  const handleNext = useCallback(() => {
    setIndex((assetIndex) => (assetIndex + 1) % assets.length)
  }, [assets.length])

  const handlePrevious = useCallback(() => {
    setIndex((assetIndex) => (assetIndex + assets.length - 1) % assets.length)
  }, [assets.length])

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code === "ArrowRight") {
        handleNext()
      }
      if (e.code === "ArrowLeft") {
        handlePrevious()
      }

      return
    },
    [handleNext, handlePrevious]
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress)
    return () => document.removeEventListener("keydown", handleKeyPress)
  }, [handleKeyPress])

  return (
    <Flex sx={{ flexDirection: "column", flex: 1 }} className={className}>
      <CarouselContainer
        assetIndex={assetIndex + offset}
        sx={{ ...sx }}
        hasDarkBackground={assets?.[assetIndex]?.hasDarkBackground}
        {...rest}
        {...swipeHandlers}
      >
        <CarouselButton
          data-ga-category="Carousel"
          data-ga-label="Previous Image"
          onClick={handlePrevious}
        />
        {assets.map((asset, index) => (
          <AssetWrapper
            key={`carousel-asset-${asset.id || index}`}
            className="asset"
            asset={asset.asset}
            alt=""
            {...asset}
          />
        ))}
        <CarouselButton
          next
          data-ga-category="Carousel"
          data-ga-label="Next Image"
          onClick={handleNext}
        />
      </CarouselContainer>
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          bottom: "0",
          left: "0",
          zIndex: 20,
        }}
        px="16px"
      >
        {textRender && <Box>{textRender()}</Box>}
        {assets.length > 1 && (
          <Flex
            sx={{
              width: "100%",
              alignItems: "center",
              fontSize: "16px",
            }}
            mt={"4px"}
          >
            <Flex sx={{ flex: "1" }}>
              <Text>
                {t("x_of_y", {
                  x: assetIndex + 1,
                  y: assets?.length,
                })}
              </Text>
            </Flex>
            <Flex
              sx={{
                ".previous,.next": {
                  margin: "0 5px",
                  cursor: "pointer",
                },

                ".previous": {
                  transform: "rotate(180deg)",
                },
              }}
            >
              <Arrow className="previous" onClick={handlePrevious} />
              <Arrow className="next" onClick={handleNext} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Carousel

import React from "react"
import styled from "@emotion/styled"

export const DesktopOnly = styled.div`
  display: none;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;

    .section-title {
      font-size: 18px;
      margin-top: 40px;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.fullHd} {
    .section-title {
      font-size: calc(26px + 0.3vw);
    }
  }
`

export const MobileOnly = styled.div`
  width: 100%;
  display: block;

  .section-title {
    font-size: 18px;
    margin-top: 20px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: none;
  }
`

import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"

import Arrow from "../../../assets/svg/signifly-arrow.svg"
import AssetWrapper from "../asset-wrapper"
import { getMediaType, isVideo } from "../../../utils/render-util"
import { pipe } from "../../../utils/functions/pipe"
import useComponentSize from "../../../hooks/useComponentSize"

const SliderContainer = styled(Flex)`
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 60vh;
  flex-direction: column;

  .asset {
    height: 100%;
    width: 100%;

    &.horizontal {
      flex: 0 0 160%;
    }

    &.vertical {
      flex: 0 0 90%;
    }

    &.video {
      flex: 0 0 100%;

      video {
        object-fit: cover;
      }
    }
  }

  svg {
    path {
      stroke: ${(props) => props.theme.colors.dark};
    }
  }

  ${(props) =>
    props.hasDarkBackground &&
    `
  color: ${props.theme.colors.white};
  
  svg {
    path {
      stroke: ${props.theme.colors.white};
    }
  }
  `}

  ${(props) => props.theme.breakpointsLegacy.mobile} {
    button {
      width: unset;
    }

    .asset {
      &.vertical {
        flex: 0 0 60%;
      }
    }
  }
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    button {
      width: unset;
    }

    .asset {
      &.horizontal {
        flex: 0 0 120%;
      }
      &.vertical {
        flex: 0 0 60%;
      }
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    height: 90vh;
    .asset {
      &.horizontal {
        flex: 0 0 100%;
      }
      &.vertical {
        flex: 0 0 40%;
      }
    }
  }
`

export const Slider = ({ assets = [], title, hideTitle, sx, ...rest }) => {
  const observer = useRef()
  const assetsRef = useRef()
  const componentSize = useComponentSize(assetsRef)
  const [assetIndex, setIndex] = useState(0)
  const { width } = componentSize

  const { t } = useTranslation()
  const currentImageText = assets?.[assetIndex]?.imageText

  useEffect(() => {
    if (!width) return
    observer.current = new IntersectionObserver(assetIntersectionCallback, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      root: assetsRef.current,
    })

    if (assetsRef?.current?.children) {
      Object.entries(assetsRef.current?.children)?.forEach(([, child]) => {
        observer.current.observe(child)
      })
    }
  }, [assetsRef, assetIntersectionCallback, width])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress)
    return () => document.removeEventListener("keydown", handleKeyPress)
  }, [handleKeyPress])

  const handleIntersectionBounds = useCallback(
    (entry) => {
      switch (true) {
        case width > 768:
        case width > 576:
          return entry.intersectionRatio > 0.8

        default:
          return entry.intersectionRatio > 0.95
      }
    },
    [width]
  )

  const assetIntersectionCallback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (handleIntersectionBounds(entry)) {
          assets.forEach((asset, index) => {
            if (entry.target?.id === asset.id) {
              setIndex(index)
            }
          })
        }
      })
    },
    [assets, handleIntersectionBounds]
  )

  const handleNext = useCallback(() => {
    const next = assetsRef.current?.children?.[assetIndex]?.nextSibling

    if (next) {
      assetsRef.current.scrollTo({
        left: next.offsetLeft - 10,
        top: next.offsetTop,
        behavior: "smooth",
      })
    }
  }, [assetIndex])

  const handlePrevious = useCallback(() => {
    const previous = assetsRef.current?.children?.[assetIndex]?.previousSibling
    if (previous)
      assetsRef.current.scrollTo({
        left: previous.offsetLeft + 10,
        top: previous.offsetTop,
        behavior: "smooth",
      })
  }, [assetIndex])

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code === "ArrowRight") {
        handleNext()
      }
      if (e.code === "ArrowLeft") {
        handlePrevious()
      }

      return
    },
    [handleNext, handlePrevious]
  )

  return (
    <SliderContainer
      assetIndex={assetIndex}
      sx={sx}
      {...rest}
      className="slider"
    >
      {!hideTitle && title && (
        <Text px={"16px"} sx={{ fontSize: "24px" }}>
          {title}
        </Text>
      )}
      <Flex
        sx={{
          flex: 1,
          overflowX: "scroll",
          overflowY: "hidden",
          scrollBehavior: "smooth",
          flexWrap: "nowrap",
          height: "100%",
        }}
        ref={assetsRef}
      >
        {Array.isArray(assets) &&
          assets.map((asset, index) => {
            const isVideoFormat = pipe(
              (value) => getMediaType(value),
              (value) => isVideo(value)
            )
            const aspectRatio = asset.asset.resize?.aspectRatio.toFixed(2)

            return (
              <AssetWrapper
                key={`carousel-asset-${asset.id || index}`}
                className={`asset ${
                  aspectRatio < 1 ? "vertical" : "horizontal"
                } ${isVideoFormat(asset.asset) && "video"}`}
                asset={asset.asset}
                index={index}
                id={asset.id}
                alt=""
                sx={{
                  aspectRatio: aspectRatio,
                  maxWidth:
                    (isVideoFormat(asset.asset) || aspectRatio < 1) && "100vw",
                  paddingRight: index !== assets.length - 1 && "1em",
                }}
                {...asset}
              />
            )
          })}
      </Flex>

      <Flex
        sx={{
          width: "100%",
          justifyContent: "space-between",
          minHeight: "20px",
          px: "16px",
          py: "8px",
        }}
        mt={"4px"}
      >
        <Text sx={{ width: "50%", whiteSpace: "break-spaces" }}>
          {currentImageText}
        </Text>
        <Flex
          sx={{
            cursor: "pointer",
            ".previous": {
              transform: "rotate(180deg)",
              marginRight: "1em",
            },
            alignItems: "center",
          }}
        >
          <Text sx={{ marginRight: "50px" }}>
            {t("x_of_y", {
              x: assetIndex + 1,
              y: assets?.length,
            })}
          </Text>
          <Arrow className="previous" onClick={handlePrevious} />
          <Arrow className="next" onClick={handleNext} />
        </Flex>
      </Flex>
    </SliderContainer>
  )
}

export default Slider

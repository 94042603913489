import React from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"

import { formatMoneyAmount } from "../../utils/prices"
import Typography from "../ui/Typography"

const Container = styled(Flex)`
  padding: 0 10px;
  font-weight: bold;
`

const ShippingNotice = styled.div`
  ${Typography.Small};
  color: ${props => props.theme.colors.dark};
  margin-bottom: 0.5rem;
  width: 100%;
`

const ShippingProgress = styled.div`
  width: 100%;
  border: 1px solid #bdc3c7;
  padding: 2px;
  margin-top: 0.3rem;
  div {
    width: ${props => (props.progress || 0.01) * 100}%;
    border-bottom: 1px solid black;

    transition: width 500ms;
  }
`

const FreeShippingProgress = ({ cart, limit, ...rest }) => {
  const { t } = useTranslation()
  const currency = cart.currencyCode
  const freeShippingAmount = limit * (1 + cart.taxRate / 100)
  const distance = freeShippingAmount - cart.subtotal * (1 + cart.taxRate / 100)
  const progress = 1 - distance / freeShippingAmount

  return (
    <Container {...rest}>
      {distance <= 0 ? (
        <ShippingNotice>{t("free_shipping_qualify")}</ShippingNotice>
      ) : (
        <ShippingNotice>
          <div>
            {t("away_from_free", {
              amount: formatMoneyAmount({
                amount: distance,
                currencyCode: currency,
              }),
            })}
          </div>
          <ShippingProgress progress={progress}>
            <div />
          </ShippingProgress>
        </ShippingNotice>
      )}
    </Container>
  )
}

export default FreeShippingProgress

import React from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"
import AssetWrapper from "../asset-wrapper"
import Carousel from "../carousel"

const HeroContainer = styled(Flex)`
  flex-basis: 100%;
  flex: 1;
  position: relative;
  height: 80vh;
  font-size: 28px;

  video {
    object-fit: cover;
  }
`

const Hero = ({ image, title, linkTo, assets, id }) => {
  if (Array.isArray(assets) && assets.length > 1) {
    return (
      <HeroContainer id={id} className="hero">
        <Carousel assets={assets} />
      </HeroContainer>
    )
  }

  const singleAssetWrapper = assets?.[0]

  return (
    <HeroContainer id={id} className="hero">
      <AssetWrapper
        asset={singleAssetWrapper?.asset ?? image}
        title={title}
        linkTo={linkTo}
        {...singleAssetWrapper}
      />
    </HeroContainer>
  )
}

export default Hero

import React, { useContext, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { Box } from "theme-ui"
import Dismiss from "../../../assets/svg/dismiss.svg"

import { InterfaceContext } from "../../../context/InterfaceContext"
import useDisableBackgroundScroll from "../../../hooks/useDisableBackgroundScroll"
import { bottomToTop, fadeIn } from "../shared/keyframes"

export const Background = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.drawerBackground};
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 11000;

  .content {
    animation: ${fadeIn} 400ms ease-in, ${bottomToTop} 400ms;
  }

  ${(props) =>
    !props.visible &&
    `
      display: none;
      
    `};
`

const Modal = () => {
  const { setModalVisible, modalVisible, modalContent } = useContext(
    InterfaceContext
  )

  const [, setScrollable] = useDisableBackgroundScroll(true)
  const containerRef = useRef()

  const handleBackgroundClicked = (e) => {
    if (containerRef.current) {
      if (!containerRef.current.contains(e.target)) {
        setModalVisible(false)
      }
    }
  }

  useEffect(() => {
    if (modalVisible) {
      setScrollable(false)
    } else {
      setScrollable(true)
    }
  }, [modalVisible])

  return (
    <Background visible={modalVisible} onClick={handleBackgroundClicked}>
      <Box className="content" ref={containerRef}>
        {modalContent}
      </Box>
    </Background>
  )
}

export default Modal

import React from "react"
import styled from "@emotion/styled"
import { contentModulesReducer } from "../../../utils/reducers/reducers"
import { ContentfulTypes } from "../../../utils/types"
import Accordion from "./Accordion"
import BlogContent from "./BlogContent"
import Gallery from "./Gallery"
import { ProductGrid } from "./Grid"
import Hero from "../../new-ui/hero"
import RichText from "../../new-ui/rich-text"
import TileSection from "../../new-ui/tile-section"
import GlobalSizeGuide from "./SizeGuide/global-size-guide"
import Slider from "../../new-ui/slider"
import Tabs from "../../new-ui/tabs"
import Button from "../../new-ui/button"
import { navigate } from "gatsby-link"

import { Box } from "theme-ui"
import { useTranslationContext } from "../../../context/TranslationContext"
import AssetWrapper from "../../new-ui/asset-wrapper"
import CareModule from "../../new-ui/care-module"
import Carousel from "../../new-ui/carousel"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"

const Content = styled(Box)`
  > * {
    margin-bottom: 1px !important;
  }

  .accordion {
    padding: 16px 0;
    width: 100%;
    justify-content: left;
    padding: 0 16px;
  }
`

const ContentModules = ({
  collection,
  allModules,
  pullUp,
  collectionPath,
  imageSizes,
  sx,
}) => {
  const { translationContext } = useTranslationContext()

  const partitioned = contentModulesReducer(allModules)

  const module = (partitioned || []).map((s, index) => {
    switch (s.internal.type) {
      case ContentfulTypes.ContentfulBlogContent:
        return (
          <BlogContent
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
          />
        )
      case ContentfulTypes.ContentfulGallery:
        return (
          <Gallery
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
          />
        )
      case ContentfulTypes.ContentfulRichText:
        return (
          <RichText
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
            sx={s.sx}
            {...s}
          />
        )
      case ContentfulTypes.ContentfulTileSection:
        return (
          <TileSection
            key={s.id || index}
            tiles={s.tiles}
            padTop={index === 0 && pullUp}
            collectionPath={collectionPath}
            displayTitle={s.displayTitle}
            sx={s.sx}
            {...s}
          />
        )
      case ContentfulTypes.ContentfulHero:
        return (
          <Hero
            id={s.id}
            key={s.id || index}
            image={s.image}
            linkTo={s.linkTo}
            assets={s.assets}
            title={s.title}
          />
        )
      case ContentfulTypes.ContentulSlider:
        return (
          <Slider
            key={s.id}
            assets={s.items}
            title={s.title}
            hideTitle={s.hideTitle}
          />
        )
      case ContentfulTypes.ContentfulAccordion:
        return (
          <Accordion
            key={s.id || index}
            input={s}
            padTop={index === 0 && pullUp}
          />
        )
      case ContentfulTypes.ContentfulProductList:
        return (
          <ProductGrid
            key={s.id || index}
            products={s.products}
            collectionPath={collectionPath}
            collection={collection}
            imageSizes={imageSizes}
          />
        )
      case ContentfulTypes.ContentfulGlobalSizeGuide:
        return <GlobalSizeGuide key={s.id || index} input={s} />

      case ContentfulTypes.ContentfulTabs:
        return (
          <Tabs
            key={s.id || index}
            tabs={s.content}
            title={s.title}
            sx={s.sx}
          />
        )

      case ContentfulTypes.ContentfulAssetWrapper:
        return <AssetWrapper key={s.id} asset={s.asset} id={s.id} {...s} />

      case ContentfulTypes.ContentfulCareModule:
        return (
          <CareModule
            key={s.id}
            title={s.title}
            content={s.content}
            backgroundColor={s.backgroundColor}
          />
        )

      case ContentfulTypes.ContentfulCarousel:
        return <Carousel key={s.id} assets={s.content} title={s.title} />

      case ContentfulTypes.ContentfulButton:
        return (
          <Button
            key={s.id || index}
            onClick={() =>
              navigate(
                handleReferenceLink({
                  reference: s.reference,
                  locale: translationContext.locale,
                })
              )
            }
            variant={s.type}
            sx={s.sx}
          >
            {s.label}
          </Button>
        )

      default:
        return null
    }
  })
  return <Content sx={sx}>{module}</Content>
}

export default ContentModules

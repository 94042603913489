import React from "react"
import styled from "@emotion/styled"
import { Link, StaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import mq from "../../utils/mq"
import Typography from "../ui/Typography"
import { ContentfulTypes } from "../../utils/types"
import { useTranslationContext } from "../../context/TranslationContext"
import { NewsletterInput } from "../new-ui/newsletter-signup"

const SocialLink = styled.a`
  ${Typography.Medium}
  color: ${(props) => props.theme.colors.text};

  :not(:last-of-type) {
    margin-right: 1rem;
  }
  &:hover {
    color: ${(props) => props.theme.colors.textHover};
  }
`

const Bottom = styled.div`
  ${Typography.Medium}
  display: flex;

  grid-area: bottom;

  border-top: 1px solid ${(props) => props.theme.colors.darkest};

  width: 100%;

  padding-top: 5px;
  padding-bottom: 5px;

  background-color: ${(props) => props.theme.colors.almostWhite};
  color: ${(props) => props.theme.colors.dark};

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    flex-direction: row;
    min-height: ${(props) => props.theme.navbar.height};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    flex: 1;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    flex-direction: row;
  }
`

const ContentContainer = styled.div`
  display: grid;
  width: 100%;
  margin: auto;

  color: ${(props) => props.theme.colors.darkest};

  ${mq({
    gridTemplateAreas: [
      `
        "care"
        "orders"
        "about"
        "bottom"
      `,
      `
        "care"
        "orders"
        "about"
        "bottom"
      `,
      `
        "care orders about"
        "bottom bottom bottom"
      `,
    ],
    gridTemplateColumns: [`1fr`, `repeat(2, 1fr)`, `1fr 1fr 1fr`],
  })}

  grid-column-gap: 20px;
  grid-row-gap: 1rem;

  a {
    color: ${(props) => props.theme.colors.darkest};
  }
`

const Wrapper = styled.footer`
  position: relative;
  background-color: ${(props) => props.theme.colors.almostWhite};
  width: 100%;
  min-height: 300px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`

const Menu = styled.div`
  ${Typography.Medium}
  grid-area: ${(props) => props.area};
  width: 100%;

  .menu-section {
    margin-bottom: 2rem;
  }

  .section {
    margin-bottom: 2rem;

    .cat {
      ${Typography.Small}
      color: ${(props) => props.theme.colors.dark};
      margin-bottom: 0.5rem;
    }

    .contact {
      display: block;
      margin-bottom: 1rem;
      text-decoration: none;
    }
  }
`

const MenuHeader = styled.div`
  ${Typography.Medium}
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.darkest};
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.darkest};
`

const MenuLink = styled(Link)`
  ${Typography.Medium}
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 0.5rem;

  &.cat {
    ${Typography.Small}
    color: ${(props) => props.theme.colors.dark};
    margin-bottom: 1rem;
  }

  &:hover {
    color: ${(props) => props.theme.colors.darkest};
  }
`

const Footer = () => {
  const { t } = useTranslation()
  const { translationContext } = useTranslationContext()
  const { locale } = translationContext

  let prefix
  switch (locale) {
    case "en-US":
      prefix = "/"
      break
    case "de-DE":
      prefix = "/de/"
  }

  return (
    <StaticQuery
      query={graphql`
        {
          supportNav: allContentfulNavigationMenu(
            filter: { name: { eq: "Support" } }
          ) {
            nodes {
              name
              id
              node_locale
              items {
                ... on ContentfulPage {
                  id
                  slug
                  pageTitle
                  internal {
                    type
                  }
                }
                ... on ContentfulNavigationItem {
                  id
                  name
                  linkTo
                  internal {
                    type
                  }
                }
              }
            }
          }
          informationNav: allContentfulNavigationMenu(
            filter: { name: { eq: "Information" } }
          ) {
            nodes {
              name
              id
              node_locale
              items {
                ... on ContentfulPage {
                  id
                  slug
                  pageTitle
                  internal {
                    type
                  }
                }
                ... on ContentfulNavigationItem {
                  id
                  name
                  linkTo
                  internal {
                    type
                  }
                }
              }
            }
          }
        }
      `}
      render={({ informationNav, supportNav }) => (
        <Wrapper>
          <ContentContainer>
            <Menu area="orders">
              <MenuHeader>{t("online_support")}</MenuHeader>
              {supportNav.nodes
                .find((node) => node.node_locale === locale)
                ?.items.map((i) => {
                  if (i.internal.type === ContentfulTypes.ContentfulPage) {
                    return (
                      <MenuLink key={i.id} to={`${prefix}${i.slug}`}>
                        {i.pageTitle}
                      </MenuLink>
                    )
                  } else if (
                    i.internal.type === ContentfulTypes.ContentfulNavigationItem
                  ) {
                    return (
                      <MenuLink key={i.id} to={`${prefix}${i.linkTo}`}>
                        {i.name}
                      </MenuLink>
                    )
                  }
                })}
            </Menu>
            <Menu area="about">
              <div className="section">
                <MenuHeader>Tekla</MenuHeader>
                {informationNav.nodes
                  .find((node) => node.node_locale === locale)
                  ?.items.map((i) => {
                    if (i.internal.type === ContentfulTypes.ContentfulPage) {
                      return (
                        <MenuLink key={i.id} to={`${prefix}${i.slug}`}>
                          {i.pageTitle}
                        </MenuLink>
                      )
                    } else if (
                      i.internal.type ===
                      ContentfulTypes.ContentfulNavigationItem
                    ) {
                      return (
                        <MenuLink key={i.id} to={`${prefix}${i.linkTo}`}>
                          {i.name}
                        </MenuLink>
                      )
                    }
                  })}
              </div>
              <div className="section">
                <MenuHeader>
                  {t("connect_with_tekla")}
                  <div>
                    <SocialLink href="https://instagram.com/teklafabrics">
                      <i className="fab fa-instagram" />
                    </SocialLink>
                    <SocialLink href="https://pinterest.dk/teklafabrics/">
                      <i className="fab fa-pinterest" />
                    </SocialLink>
                    <SocialLink href="https://facebook.com/teklafabrics">
                      <i className="fab fa-facebook" />
                    </SocialLink>
                    <SocialLink href="https://open.spotify.com/user/bj3q21zapzgtcl03gy9o7x2of?si=ON0IdDyLTb6ZmXmyAOQl5w">
                      <i className="fab fa-spotify" />
                    </SocialLink>
                  </div>
                </MenuHeader>
              </div>
            </Menu>
            <Menu area="care">
              <div className="section">
                <MenuHeader>{t("customer_care")}</MenuHeader>
                <div>
                  <div className="cat">{t("email")}</div>
                  <a
                    className="contact"
                    href="mailto:customercare@teklafabrics.com "
                  >
                    customercare@teklafabrics.com
                  </a>
                  <div className="cat">{t("phone")}</div>
                  <a className="contact" href="tel:+4531411137">
                    +45 314 111 37
                  </a>
                </div>
              </div>
              <div className="section">
                <NewsletterInput showDescription={true} />
              </div>
            </Menu>
            <Bottom>
              <Container>
                <div />
                <div>
                  © {new Date().getFullYear()} Tekla. All rights reserved.
                </div>
                <div />
              </Container>
            </Bottom>
          </ContentContainer>
        </Wrapper>
      )}
    />
  )
}

export default Footer

import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import Medusa from "../../services/api"
import { countryLookup } from "../../utils/countries"
import { MobileOnly } from "../ui/ConditionRender"
import { useTranslationContext } from "../../context/TranslationContext"
import { Link } from "gatsby"
import { Flex, Text } from "theme-ui"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"

const RegionContainer = styled(Flex)`
  position: fixed;
  height: 100%;
  bottom: 0;
  right: 0;

  width: 100%;
  flex-direction: column;

  padding: 0 10px;
  padding-bottom: 1rem;
  padding-top: calc(${(props) => props.theme.navbar.height} + 0.5rem);

  ${(props) =>
    props.promotionalVisible &&
    `
    padding-top: calc(${props.theme.navbar.promotional} + 0.5rem);

  `}

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};

  transition: visibility 0s, opacity 0.2s linear;
  box-shadow: -19px 0 99px 0 rgba(0, 0, 0, 0.07),
    -10px 0 50px 0 rgba(0, 0, 0, 0.1);

  background-color: ${(props) => props.theme.colors.almostWhite};
  z-index: 8500;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    width: 420px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Header = styled(Flex)`
  display: flex;
  justify-content: space-between;
  .header {
    flex: 1;
  }
  margin-bottom: 48px;
`

const CountriesContainer = styled(Flex)`
  display: flex;
  flex: 1;
  overflow: scroll;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.almostWhite};

  .country-container {
    h2 {
      font-size: 19px;
      font-weight: 300;
    }
  }

  .bar-icon {
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.colors.darkest};
    flex-direction: row;
    padding: 10px;
    padding-left: 4%;

    .icon {
      align-self: center;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    border: none;

    flex-basis: 100%;

    .bar-icon {
      padding-left: 35px;
    }

    .country-container {
      padding-left: 60px;
      margin: 12px 0;
      h2 {
        font-size: 19px;
        font-weight: 300;
      }
    }
  }
`

const Countries = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  font-size: 15px;
  max-height: 100%;
  overflow: scroll;
  margin-bottom: 8px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    font-size: ${(props) => props.theme.fontSize.m};
  }
`

const Country = styled.div`
  line-height: 1.25;
  margin-bottom: 18px;

  cursor: pointer;

  a {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    margin-bottom: 14px;
  }
`

const Info = styled(Flex)`
  display: none;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    bottom: 0;
    padding-bottom: 20px;
    display: flex;
    flex: 1;
    padding-left: 60px;
  }
`

const RegionDrawer = ({ visible, onSetCountry, promotionalVisible }) => {
  const { t } = useTranslation()
  const [countries, setCountries] = useState(() => {
    const defaultCountries = {
      allCountries: [],
      visibleCountries: [],
    }

    return defaultCountries
  })

  const { translationContext } = useTranslationContext()
  const [, setScrollable] = useDisableBackgroundScroll()

  useEffect(() => {
    if (visible) {
      setScrollable(false)
    } else {
      setScrollable(true)
    }
  }, [visible, setScrollable])

  useEffect(() => {
    Medusa.regions
      .list()
      .then(({ data }) => {
        let countries = data.regions.map((region) => {
          return region.countries.map((country) => {
            const countryDisplayName = countryLookup(
              country.iso_2.toUpperCase()
            )

            return {
              name: countryDisplayName,
              country_code: country.iso_2.toUpperCase(),
              currency_code: region.currency_code,
              region: region.name,
              region_id: region.id,
            }
          })
        })

        return countries.flat().sort((a, b) => sortByName(a, b))
      })
      .then((countries) => {
        setCountries({ allCountries: countries, visibleCountries: countries })
      })
  }, [])

  const sortByName = (a, b) => {
    if (a.name > b.name) {
      return 1
    } else if (b.name > a.name) {
      return -1
    } else {
      return 0
    }
  }

  const handleLanguageChange = (lang) => {
    switch (lang) {
      case "en-US":
        return translationContext?.canonicals?.["en-US"] || "/"
      case "de-DE":
        return translationContext?.canonicals?.["de-DE"] || "/"
      default:
        break
    }
  }

  return (
    <RegionContainer visible={visible} promotionalVisible={promotionalVisible}>
      <MobileOnly>
        <Header>
          <Text sx={{ fontSize: "21px" }}>{t("country_currency")}</Text>
        </Header>
      </MobileOnly>
      <CountriesContainer>
        <div className="country-container">
          <h2>{t("choose_language")}</h2>
          <Countries>
            <Country>
              <Link to={handleLanguageChange("en-US")}>{t("english")}</Link>
            </Country>
            <Country>
              <Link to={handleLanguageChange("de-DE")}>{t("german")}</Link>
            </Country>
          </Countries>
        </div>
        <div className="country-container">
          <h2>{t("choose_location")}</h2>
          <Countries>
            {countries.allCountries &&
              countries.visibleCountries.map((country) => {
                return (
                  <Country
                    key={country.name}
                    onClick={() => onSetCountry(country)}
                  >
                    {country.name}
                  </Country>
                )
              })}
          </Countries>
        </div>
      </CountriesContainer>
      <Info>{t("region_pick_prompt")}</Info>
    </RegionContainer>
  )
}

export default RegionDrawer

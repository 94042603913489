import React, { useRef, useEffect } from "react"
import styled from "@emotion/styled"
import Input from "../../ui/Input"
import { Row, ActionText } from "./elements"
import Button from "../../ui/Button"
import { useTranslation } from "react-i18next"

const Container = styled.div`
  width: 100%;
`

const IdentifyStep = ({
  showPassword,
  password,
  email,
  failed,
  onSetHeader,
  onCheckEmail,
  onLogin,
  onForgot,
  onFieldChange,
}) => {
  const emailRef = useRef(null)
  const passRef = useRef(null)
  const { t } = useTranslation()
  useEffect(() => {
    onSetHeader(showPassword ? t("welcome_back") : t("sign_in_or_create"))
    if (emailRef) {
      emailRef.current.focus()
    }
    if (passRef && showPassword) {
      passRef.current.focus()
    }
  }, [emailRef, passRef, showPassword])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (showPassword) {
        onLogin()
      } else {
        onCheckEmail()
      }
    }
  }

  return (
    <Container>
      {showPassword && (
        <Row pt="1rem">
          <div className="small">{t("enter_password")}</div>
        </Row>
      )}
      {failed && (
        <Row pt="1rem">
          <div className="small danger">{t("unrecognized_pass")}</div>
        </Row>
      )}
      <Row>
        <Input
          refFunc={emailRef}
          className="input"
          maxLength="50"
          placeholder={t("enter_email")}
          name="email"
          value={email}
          onChange={onFieldChange}
          onKeyPress={handleKeyDown}
        />
      </Row>
      {showPassword && (
        <Row>
          <div className="input">
            <Input
              refFunc={passRef}
              password
              className="input"
              maxLength="50"
              placeholder={t("enter_password")}
              name="password"
              value={password}
              onChange={onFieldChange}
              onKeyPress={handleKeyDown}
            />
            <ActionText onClick={onForgot}>{t("forgot_password")}</ActionText>
          </div>
        </Row>
      )}
      <Row pt="6px">
        <Button onClick={showPassword ? onLogin : onCheckEmail}>
          {showPassword ? t("login") : t("next")}
        </Button>
      </Row>
    </Container>
  )
}

export default IdentifyStep

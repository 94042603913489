import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Link, navigate } from "gatsby"
import isTouchDevice from "is-touch-device"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"

import WhiteLogo from "../../assets/logo-white.svg"
import Logo from "../../assets/logo.png"
import CloseIcon from "../../assets/svg/close.svg"
import { AccountContext } from "../../context/AccountContext"
import { InterfaceContext } from "../../context/InterfaceContext"
import StoreContext from "../../context/StoreContext"
import { useTranslationContext } from "../../context/TranslationContext"
import useWindowSize from "../../hooks/useWindowSize"
import getDisplayItems from "../../utils/get-display-items"
import { isDesktopWindow, isMobileWindow } from "../../utils/render-util"
import { handleUrlPrefix } from "../../utils/translations-util"
import MenuWrapper from "../new-ui/product-menu"
import PromotionalElement from "../new-ui/promotional-element"
import LoadingSpinner from "../ui/LoadingSpinner"
import CartDrawer from "./../new-ui/cart/cart-drawer"
import { CartBag, DismissCatch, HeaderLogo, NavBar, NavItem } from "./elements"
import LoginDrawer from "./LoginDrawer"
import RegionDrawer from "./RegionDrawer"

const Account = styled.div`
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${(props) =>
    props.hide &&
    `
  span {
    visibility: hidden;
  }
  `}

  .mobile {
    display: inline-block;
  }
  .desktop {
    display: none;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    margin-left: 1rem;
    ${(props) => props.regionVisible && `flex: 1`};

    .desktop {
      display: inline-block;
    }
    .mobile {
      display: none;
    }
  }
`

const Region = styled.div`
  display: none;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: block;
  }
`

const RightMenu = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-end;
  padding-left: 10px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    width: 386px;
  }

  color: ${(props) =>
    props.isWhite ? props.theme.colors.white : props.theme.colors.black};
`

const LeftMenu = styled.div`
  color: ${(props) =>
    props.isWhite ? props.theme.colors.white : props.theme.colors.black};
`

const Header = ({
  fixedNav,
  navbarInWhite,
  navbarTransparent,
  withPromoElement,
  promoInput,
}) => {
  const {
    scrollPos,
    prevScrollPos,
    showProductNav,
    showCart,
    setModalContent,
    header,
    toggleModal,
    toggleRegionOpen,
    hideDrawers,
    isLoginOpen,
    isCartOpen,
    isProductNavOpen,
    isRegionOpen,
  } = useContext(InterfaceContext)

  const { t } = useTranslation()
  const { loggedIn, logOut, name } = useContext(AccountContext)
  const [isAccount, setIsAccount] = useState(false)
  const windowSize = useWindowSize()
  const { translationContext } = useTranslationContext()

  const {
    cart,
    freeShippingLimit,
    updateLineItem,
    removeCouponCode,
    addCouponCode,
    removeLineItem,
    removeBundleItem,
    setRegion,
  } = useContext(StoreContext)

  useEffect(() => {
    if (typeof window !== "undefined") {
      let account = window.location.pathname.includes("account")
      setIsAccount(account)
    }
  }, [])

  const handleProductNavClick = () => {
    if (isProductNavOpen && !isTouchDevice()) {
      navigate(
        handleUrlPrefix(translationContext.locale, t("all_products_url"))
      )
    } else if (!isProductNavOpen && isTouchDevice()) {
      if (!isCartOpen && showProductNav) {
        showProductNav()
      }
    }
  }

  const handleSetCountry = (country) => {
    setRegion(country.region_id, country.country_code)
    toggleRegionOpen()
  }

  const [cartLoading, setCartLoading] = useState(true)

  const itemsInCart = () => {
    const i = getDisplayItems(cart)
    return i.reduce((total, item) => total + item.quantity, 0)
  }

  useEffect(() => {
    if (cart.id != undefined) {
      setCartLoading(false)
    }
  }, [cart])

  const homeUrl = handleUrlPrefix(translationContext.locale, "")

  return (
    <header>
      <DismissCatch
        active={isLoginOpen || isCartOpen || isProductNavOpen || isRegionOpen}
        onMouseOver={() => {
          if (isLoginOpen || isCartOpen || isProductNavOpen || isRegionOpen) {
            hideDrawers()
          }
        }}
        onClick={() => {
          if (isLoginOpen || isCartOpen || isProductNavOpen || isRegionOpen) {
            hideDrawers()
          }
        }}
      />
      <NavBar
        id="navbar"
        fixedNav={fixedNav}
        isTransparent={scrollPos === 0 || navbarTransparent}
        isUp={scrollPos - prevScrollPos > 0}
        isProductNavOpen={isProductNavOpen}
        fixed={isCartOpen || isProductNavOpen}
        isWhite={navbarInWhite}
        promotionalVisible={withPromoElement}
      >
        {withPromoElement && <PromotionalElement input={promoInput} />}
        <Flex
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "baseline",
            p: "10px",
            minHeight: "46px",
          }}
        >
          <LeftMenu
            isWhite={navbarInWhite && !isProductNavOpen && scrollPos === 0}
          >
            <NavItem justifySelf="start">
              <Link style={{ lineHeight: "1rem" }} to={homeUrl || "/"}>
                {navbarInWhite && scrollPos === 0 && !isProductNavOpen ? (
                  <HeaderLogo alt="Tekla Fabrics Logo" src={WhiteLogo} />
                ) : (
                  <HeaderLogo alt="Tekla Fabrics Logo" src={Logo} />
                )}
              </Link>
              {(!isProductNavOpen || isDesktopWindow(windowSize.width)) && (
                <div
                  onClick={handleProductNavClick}
                  onMouseOver={showProductNav}
                >
                  {t("products")}
                </div>
              )}
            </NavItem>
            <NavItem header justifySelf="flex-start">
              {header}
            </NavItem>
          </LeftMenu>
          <NavItem justifySelf="flex-end">
            {cartLoading ? (
              <LoadingSpinner />
            ) : (
              <RightMenu
                isWhite={
                  navbarInWhite &&
                  !isCartOpen &&
                  !isRegionOpen &&
                  !isLoginOpen &&
                  scrollPos === 0
                }
              >
                <Region onClick={toggleRegionOpen} visible={isRegionOpen}>
                  {cart.countryCode?.toUpperCase()}&#8201;/&#8201;
                  {cart.currencyCode?.toUpperCase()}
                </Region>
                {(!isProductNavOpen || isDesktopWindow(windowSize.width)) && (
                  <Account
                    regionVisible={isRegionOpen}
                    onClick={() => {
                      hideDrawers()
                      if (loggedIn) {
                        if (isAccount) {
                          logOut().then(() => navigate("/"))
                        } else {
                          navigate(
                            handleUrlPrefix(
                              translationContext.locale,
                              `${t("account_url")}/${t("welcome_back_url")}`
                            )
                          )
                        }
                      } else {
                        navigate(
                          handleUrlPrefix(
                            translationContext.locale,
                            `${t("account_url")}/${t("sign_in_url")}`
                          )
                        )
                      }
                    }}
                  >
                    <span className="desktop">
                      {loggedIn ? (isAccount ? "Log out" : name) : "Login"}
                    </span>
                    <span className="mobile">
                      <i className="fal fa-user-alt" />
                    </span>
                  </Account>
                )}
                <CartBag onClick={showCart} onMouseOver={showCart}>
                  {t("cart")} (&#8201;{itemsInCart()}&#8201;)
                </CartBag>
                {isProductNavOpen && isMobileWindow(windowSize.width) && (
                  <Flex
                    sx={{
                      width: "15px",
                      marginLeft: "32px",
                      marginRight: "8px",
                    }}
                  >
                    <CloseIcon onClick={hideDrawers} />
                  </Flex>
                )}
              </RightMenu>
            )}
          </NavItem>
        </Flex>
      </NavBar>
      <MenuWrapper />
      <LoginDrawer visible={isLoginOpen} hideDrawers={hideDrawers} />
      <CartDrawer
        onRemoveCouponCode={removeCouponCode}
        onAddCouponCode={addCouponCode}
        onRemoveLine={removeLineItem}
        onRemoveBundle={removeBundleItem}
        onQuantityUpdate={updateLineItem}
        setModalContent={setModalContent}
        hideDrawers={hideDrawers}
        toggleModal={toggleModal}
        onSetRegion={setRegion}
        visible={isCartOpen}
        cart={cart}
        freeShippingLimit={freeShippingLimit}
        promotionalVisible={withPromoElement}
      />
      <RegionDrawer
        visible={isRegionOpen}
        onSetCountry={handleSetCountry}
        toggleRegionOpen={toggleRegionOpen}
        promotionalVisible={withPromoElement}
      />
    </header>
  )
}

export default Header

import React, { useState } from "react"

import styled from "@emotion/styled"
import SizeGuide from "../../../ProductPage/SizeGuide"
import Arrow from "../../../../assets/svg/signifly-arrow.svg"
import RichText from "../../../new-ui/rich-text"
import { Trans, useTranslation } from "react-i18next"

const Container = styled.div`
  margin: 80px 0px;

  &.desktop {
    display: none;

    ${(props) => props.theme.breakpointsLegacy.desktop} {
      display: flex;
      flex-direction: row;
    }
  }

  &.mobile {
    display: flex;
    flex-direction: column;

    .page-header,
    .hint {
      margin-left: 16px;
    }
    .page-header {
      font-size: 26px;
      margin-bottom: 0px;
    }

    .hint {
      font-size: 11px;
      margin-top: 36px;
      margin-bottom: 0px;
    }

    ${(props) => props.theme.breakpointsLegacy.desktop} {
      display: none;
    }
  }
`

const ContentContainer = styled.div`
  flex: 1;

  .hint {
    font-size: 13px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 36px;
    font-weight: normal;
  }

  a {
    font-weight: bold;
  }

  .contact {
    opacity: 50%;
    width: 60%;
  }

  .body {
    padding: 10px 0;

    > div {
      padding: 0;
      margin: 0;
    }

    &.support {
      opacity: 0.5;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    &.no-selection {
      margin: 0px auto;
      max-width: 600px;
    }

    .category-headers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }

    .category-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      border-top: 1px solid black;
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 52px;

      &:hover {
        .icon {
          display: block;
        }
      }
    }

    .icon {
      display: none;
      margin-right: 10px;
    }
  }
`

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 250px;
  margin-left: 110px;

  border-right: ${(props) => props.theme.borders.blackTransparent};
`

const CatMenuHeader = styled.div`
  margin-bottom: 24px;
  opacity: ${(props) => (props.active ? "1" : "0.3")};
  border-right: ${(props) =>
    props.withMarker && props.active && props.theme.borders.black};
  margin-right: -1px;

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: ${(props) => props.active && "bold"};

    .icon {
      display: ${(props) => (props.withMarker ? "flex" : "none")};
      margin-right: 16px;
    }
  }

  .collections {
    display: ${(props) => (props.active ? "flex" : "none")};
    flex-direction: column;
  }
`

const Select = styled.select`
  background-color: ${(props) => props.theme.colors.almostWhite};
  padding: 10px 15px;
  margin: 4px 0;
  width: -webkit-fill-available;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`

const SelectWrapper = styled.div`
  .select-icon {
    margin-left: -10%;
    transform: rotate(90deg);
  }
`

const SelectContainer = styled.div`
  margin-bottom: 16px;

  &.with-margin {
    margin: 4px 16px;
  }
`

const FurtherQuestions = styled.div`
  margin: 16px;
  opacity: 0.5;
  padding-bottom: 80px;

  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkest};
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 20px 0;
  }
`

const GlobalSizeGuide = ({ input }) => {
  const { categories, description } = input
  const [currentCategory, setCurrentCategory] = useState(null)
  const { t } = useTranslation()

  const handleSizeguides = (categoryId) => {
    const category = categories?.find((category) => category.id === categoryId)

    const sizeGuideGroups = category.collections
      .filter((collection) => collection.sizeGuideGroups !== null)
      .map((collection) => collection.sizeGuideGroups)
      .flat()
      .reduce((acc, current) => {
        if (acc.some((group) => group.title === current.title)) {
          return acc
        } else {
          acc.push(current)
        }
        return acc
      }, [])

    return sizeGuideGroups
  }

  return (
    <>
      <Container className="desktop">
        <SideMenu>
          {categories.map((category) => {
            return (
              <CatMenuHeader
                key={category.id}
                active={currentCategory === category.id}
              >
                <div
                  className="category"
                  onClick={() => setCurrentCategory(category.id)}
                >
                  {category.name}
                  {category.id === currentCategory && (
                    <Arrow className="icon" />
                  )}
                </div>
              </CatMenuHeader>
            )
          })}
        </SideMenu>
        {currentCategory ? (
          <ContentContainer>
            <SizeGuide sizeGuideGroups={handleSizeguides(currentCategory)} />
          </ContentContainer>
        ) : (
          <ContentContainer className="no-selection">
            <p className="hint">{t("select_product")}</p>
            <h2 className="page-header">{t("size_guide")}</h2>

            <RichText className="body" input={{ body: description }} />

            <div className="category-headers">
              {categories.map((category) => {
                return (
                  <div
                    key={`${category.id}-size-guide`}
                    className="category-header"
                    onClick={() => setCurrentCategory(category.id)}
                  >
                    {category.name}
                    <Arrow className="icon" />
                  </div>
                )
              })}
            </div>
            <FurtherQuestions>
              <Trans i18nKey="further_questions_sizing">
                Need further guidance? We are at your disposal so please do not
                hesitate in contacting our customer care via email at
                <a href="mailto:sizing@teklafabrics.com">
                  sizing@teklafabrics.com
                </a>
                or via phone on <a href="tel:+4522745176">+45 22 74 51 76</a>,
                Monday-Friday 9AM-5PM (CET+1).
              </Trans>
            </FurtherQuestions>
          </ContentContainer>
        )}
      </Container>
      <Container className="mobile">
        {!currentCategory && (
          <>
            <h2 className="page-header">{t("size_guide")}</h2>
            <p className="hint">{t("select_product")}</p>
            <SelectContainer className="with-margin">
              <SelectWrapper>
                <Select
                  value={currentCategory || ""}
                  onChange={(e) => {
                    setCurrentCategory(e.target.value)
                  }}
                >
                  {!currentCategory && <option>{t("select_product")}</option>}
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  })}
                </Select>
                <Arrow className="select-icon" />
              </SelectWrapper>
            </SelectContainer>
          </>
        )}
        {currentCategory ? (
          <ContentContainer>
            <SizeGuide
              sizeGuideGroups={handleSizeguides(currentCategory)}
              selectComponent={
                <SelectContainer>
                  <p className="hint">{t("select_product")}</p>
                  <SelectWrapper>
                    <Select
                      value={currentCategory}
                      onChange={(e) => setCurrentCategory(e.target.value)}
                    >
                      {categories.map((category) => {
                        return (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        )
                      })}
                    </Select>
                    <Arrow className="select-icon" />
                  </SelectWrapper>
                </SelectContainer>
              }
            />
          </ContentContainer>
        ) : (
          <ContentContainer className="no-selection">
            <FurtherQuestions>
              <Trans i18nKey="further_questions_sizing">
                Need further guidance? We are at your disposal so please do not
                hesitate in contacting our customer care via email at
                <a href="mailto:sizing@teklafabrics.com">
                  sizing@teklafabrics.com
                </a>
                or via phone on <a href="tel:+4522745176">+45 22 74 51 76</a>,
                Monday-Friday 9AM-5PM (CET+1).
              </Trans>
            </FurtherQuestions>
          </ContentContainer>
        )}
      </Container>
    </>
  )
}

export default GlobalSizeGuide

import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { useTranslation } from "react-i18next"

import useDisableBackgroundScroll from "../../../hooks/useDisableBackgroundScroll"
import { handleUrlPrefix } from "../../../utils/translations-util"
import { ContentfulTypes } from "../../../utils/types"
import {
  CategorySection,
  ProductNav,
  Products,
  ScrollContainer,
  RegionSelection,
} from "./index"
import { InterfaceContext } from "../../../context/InterfaceContext"
import StoreContext from "../../../context/StoreContext"
import { Flex, Text } from "theme-ui"
import BackIcon from "../../../assets/svg/down-arrow-small.svg"
import { useTranslationContext } from "../../../context/TranslationContext"

const MobileProductMenu = ({ items }) => {
  const { i18n, t } = useTranslation()

  const [active, setActive] = useState(null)
  const [, setScrollable] = useDisableBackgroundScroll()

  const { hideDrawers, isProductNavOpen, showRegion } = useContext(
    InterfaceContext
  )
  const { translationContext } = useTranslationContext()

  const { cart } = useContext(StoreContext)

  const { countryCode, currencyCode } = cart

  useEffect(() => {
    if (isProductNavOpen) {
      setScrollable(false)
    } else {
      setScrollable(true)
    }
  }, [isProductNavOpen, setScrollable])

  /*
    Creates the collection slug based on the products.
    If it only contains one product, return the specific product slug,
    otherwise return collection list slug
  */
  const createCollectionSlug = (baseSlug, collection) => {
    if (collection.fullSlug) {
      return collection.fullSlug
    }
    const collectionSlug = collection.slug
    return handleUrlPrefix(i18n.language, `l/${baseSlug}/${collectionSlug}/`)
  }

  const handleCatClick = (e, cat) => {
    e.preventDefault()
    e.stopPropagation()

    if (cat.collections.length === 1) {
      navigate(
        handleUrlPrefix(
          i18n.language,
          `l/${cat.slug}/${cat.collections[0].slug}`
        )
      )
      hideDrawers()
    } else if (active === cat.id) {
      navigate(handleUrlPrefix(i18n.language, `l/${cat.slug}`))
      hideDrawers()
    } else {
      setActive(cat.id)
    }
  }

  return (
    <ProductNav visible={isProductNavOpen}>
      <ScrollContainer>
        <Products>
          <CategorySection>
            <Link
              to="/l/all-products"
              onClick={hideDrawers}
              className="category-item link"
            >
              <Text>{t("all_products")}</Text>
            </Link>
          </CategorySection>
          {items &&
            items.length > 0 &&
            items.map((item) => {
              if (item.internal.type === ContentfulTypes.ContentfulCategory) {
                const cat = item
                return (
                  <CategorySection key={cat.id} active={cat.id === active}>
                    <span
                      onClick={(e) => handleCatClick(e, cat)}
                      className="category-item link"
                    >
                      <div>{cat.name}</div>
                    </span>
                    <div
                      className={
                        active === cat.id ? "collections active" : "collections"
                      }
                    >
                      <Flex
                        sx={{ alignItems: "center" }}
                        className="collection-item"
                        onClick={() => setActive(null)}
                      >
                        <BackIcon className="back-icon" />
                        <Text sx={{ marginLeft: "16px", fontWeight: "bold" }}>
                          {cat.name}
                        </Text>
                      </Flex>
                      {cat.collections.length >= 1 &&
                        cat.collections.map((collection) => {
                          let fullSlug = createCollectionSlug(
                            cat.slug,
                            collection
                          )

                          return (
                            <Link
                              onClick={hideDrawers}
                              key={collection.id}
                              className="collection-item"
                              to={fullSlug}
                            >
                              {collection.name}
                            </Link>
                          )
                        })}
                    </div>
                  </CategorySection>
                )
              }
            })}
          <CategorySection>
            <Link
              to={handleUrlPrefix(translationContext.locale, "journal")}
              onClick={hideDrawers}
              className="category-item link"
            >
              <Text>The Journal</Text>
            </Link>
          </CategorySection>
        </Products>

        <RegionSelection onClick={showRegion}>
          {countryCode && currencyCode && (
            <>
              {t("country_currency")}:{" "}
              <Text sx={{ display: "inline-block", fontWeight: "bold" }}>
                {countryCode.toUpperCase()}
                &#8201;/&#8201;
                {currencyCode.toUpperCase()}
              </Text>
            </>
          )}
        </RegionSelection>
      </ScrollContainer>
    </ProductNav>
  )
}

export default MobileProductMenu

import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import Link from "../../../utils/Link"
import {
  Container,
  ContentBlock,
  Image,
  ImageIndicator,
  ImageIndicatorContainer,
  ImageWrapper,
  ScrollContainer,
} from "./elements"

const ImageContainer = ({ link, image, index, onInView, alt }) => {
  const [ref, inView, entry] = useInView({ threshold: 0.2 })

  useEffect(() => {
    if (inView) {
      onInView(index)
    }
  }, [inView])

  return (
    <div ref={ref}>
      <Link to={link}>
        <Image image={image.gatsbyImageData} alt={alt} />
      </Link>
    </div>
  )
}

const Carousel = ({ items, onChange }) => {
  const scroller = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const ShowIndicators = () => {
    if (items.length > 3) {
      return (
        <div>
          {activeIndex + 1}&#8201;/&#8201;{items.length}
        </div>
      )
    } else if (items.length > 1) {
      return items.map((item, index) => (
        <ImageIndicator
          key={`active-${item.image.id}`}
          active={index === activeIndex}
        />
      ))
    } else {
      return <></>
    }
  }

  return (
    <Container>
      <ScrollContainer>
        <ImageWrapper ref={scroller} index={activeIndex} count={items.length}>
          {items.map((item, index) => {
            return (
              <ImageContainer
                key={item.image.id}
                image={item.image}
                link={item.linkUrl}
                onInView={() => setActiveIndex(index)}
                index={index}
                alt=""
              />
            )
          })}
        </ImageWrapper>
      </ScrollContainer>
      <ContentBlock>
        {items[activeIndex].content}
        <ImageIndicatorContainer>{ShowIndicators()}</ImageIndicatorContainer>
      </ContentBlock>
    </Container>
  )
}

export default Carousel

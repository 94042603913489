import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import "@stripe/stripe-js"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby-link"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import "@fortawesome/fontawesome-pro/css/all.min.css"

import NotificationContext from "../../context/NotificationContext"
import { useStoreContext } from "../../context/StoreContext"
import { useTranslationContext } from "../../context/TranslationContext"
import "../../fonts/styles.css"
import ErrorBoundary from "../ErrorBoundary"
import Footer from "../Footer"
import Header from "../Header"
import Modal from "../new-ui/modal"
import { NewsletterSignup } from "../new-ui/newsletter-signup"
import NotificationContainer from "../Notifications/NotificationContainer"
import "./layout.css"

const Main = styled.main`
  flex: 1;
  display: flex;
  min-height: 90vh;
  flex-direction: column;

  ${(props) => `
    ${!props.pullUp && `margin-top: ${props.theme.navbar.height}`};
    ${
      props.withPromoElement && `padding-top: ${props.theme.navbar.promoHeight}`
    };
  `}

  background-color: ${(props) =>
    props.almostWhite
      ? props.theme.colors.almostWhite
      : props.theme.colors.white};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const UrlActionTypes = {
  ADD_TO_CART: "add-to-cart",
}

const query = graphql`
  query {
    defaultElement: allContentfulPromotionalElement(
      filter: { title: { eq: "Global" } }
    ) {
      nodes {
        ...PromotionalElement
      }
    }
    regions: allContentfulRegion {
      nodes {
        ...Region
      }
    }
  }
`

const Layout = ({
  children,
  pullUp,
  fixedNav,
  almostWhite,
  navbarInWhite,
  navbarTransparent = false,
  hideFooter = false,
  hidePromotional = false,
}) => {
  const { pushNotification, dismissNotification } =
    useContext(NotificationContext)
  const { translationContext } = useTranslationContext()
  const { cart, addVariantToCart } = useStoreContext()
  const { t } = useTranslation()
  const [renderNewsletter, setRenderNewsletter] = useState(false)
  const data = useStaticQuery(query)
  const [promoInput, setPromoInput] = useState()

  useEffect(() => {
    const handleAddVariantFromAction = ({ variantId, quantity, metadata }) => {
      addVariantToCart({
        variantId: variantId,
        quantity: quantity,
        metadata: metadata,
      })
        .then(() => navigate("/checkout"))
        .catch(() =>
          pushNotification({
            id: "add-from-action-failed",
            body: t("something_went_wrong"),
            dismiss: {
              duration: 3000,
            },
          })
        )
    }

    if (typeof window !== "undefined" && cart?.id) {
      if (!window.location.search) return

      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      if (params.action) {
        switch (params.action) {
          case UrlActionTypes.ADD_TO_CART: {
            const variantId = params.variant_id
            const quantity = params.quantity || 1
            const metadata = params.metadata || {}

            handleAddVariantFromAction({
              variantId: variantId,
              quantity: quantity,
              metadata: metadata,
            })

            break
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.id])

  useEffect(() => {
    const nlHandled = localStorage.getItem("nlp")
    if (!nlHandled) {
      setTimeout(() => {
        setRenderNewsletter(true)
      }, 3000)
    }
  }, [dismissNotification, pushNotification])

  useEffect(() => {
    let result = data.defaultElement.nodes.find(
      (element) => element.node_locale === translationContext.locale
    )

    if (cart.region && data.regions) {
      const region = data.regions.nodes
        .filter((region) => region.node_locale === translationContext.locale)
        .find((region) => region.name === cart?.region?.name)

      if (region && region.promotionalElement) {
        result = region?.promotionalElement
      }
    }
    setPromoInput(result || null)
  }, [data, cart.region, translationContext.locale])

  return (
    <ErrorBoundary>
      <Wrapper>
        <NotificationContainer promotionalVisible={!hidePromotional} />
        <Modal />

        {renderNewsletter && (
          <Flex
            sx={{
              position: "fixed",
              zIndex: 8000,
              right: 0,
              top: [0, "initial"],
              left: [0, "initial"],
              bottom: 0,
              margin: "16px",
              justifyContent: ["center", "flex-end"],
              alignItems: ["center", "flex-end"],
            }}
          >
            <NewsletterSignup
              showImage={true}
              handleClose={() => {
                localStorage.setItem("nlp", true)
                setRenderNewsletter(false)
              }}
              onComplete={() => {
                localStorage.setItem("nlp", true)
                setRenderNewsletter(false)
              }}
            />
          </Flex>
        )}
        <Header
          fixedNav={fixedNav}
          navbarInWhite={navbarInWhite}
          navbarTransparent={navbarTransparent}
          withPromoElement={!hidePromotional && Boolean(promoInput)}
          promoInput={promoInput}
        />
        <Main
          pullUp={pullUp}
          almostWhite={almostWhite}
          withPromoElement={!hidePromotional && Boolean(promoInput)}
        >
          {children}
        </Main>
        {!hideFooter && <Footer />}
      </Wrapper>
    </ErrorBoundary>
  )
}

export default Layout

import { ContentfulTypes } from "../types"

export const contentModulesReducer = modules => {
  const partitioned = modules?.reduce((acc, current) => {
    const index = acc.length - 1
    if (
      current.internal.type === ContentfulTypes.ContentfulProduct ||
      current.internal.type === ContentfulTypes.ContentfulBeddingBundle
    ) {
      if (acc[index] && Array.isArray(acc[index].products)) {
        acc[index].products = [...acc[index].products, current]
      } else {
        acc.push({
          id: `product-list-${current.id}`,
          internal: { type: ContentfulTypes.ContentfulProductList },
          products: [current],
        })
      }
    } else {
      acc.push(current)
    }

    return acc
  }, [])

  return partitioned
}

export const sum = (a, b) => a + b

import React, { useContext, useState } from "react"
import styled from "@emotion/styled"

import StoreContext from "../../../context/StoreContext"
import { trackRemoveFromCart } from "../../../services/analytics"
import CartSummary from "./cart-summary"

const CartDrawerContainer = styled.div`
  position: relative;
  width: 100%;

  max-height: 100vh;

  display: flex;
  flex-direction: column;

  padding-bottom: 1rem;

  position: fixed;

  padding-top: calc(${(props) => props.theme.navbar.height} + 0.5rem);
  height: 100%;
  bottom: 0;
  right: 0;

  ${(props) =>
    props.promotionalVisible &&
    `
    padding-top: calc(${props.theme.navbar.promotional} + 0.5rem);

  `}

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};

  transition: visibility 0s, opacity 0.2s linear;
  box-shadow: -19px 0 99px 0 rgba(0, 0, 0, 0.07),
    -10px 0 50px 0 rgba(0, 0, 0, 0.1);

  background-color: ${(props) => props.theme.colors.almostWhite};
  z-index: 1001;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    width: 50vw;
  }
`

const CartDrawer = ({ visible, promotionalVisible }) => {
  const [gcError, setGcError] = useState(null)
  const [ccError, setCcError] = useState(null)

  const {
    cart,
    update,
    updateLineItem,
    removeCouponCode,
    freeShippingLimit,
    removeLineItem,
  } = useContext(StoreContext)

  const handleRemove = ({
    id,
    ids,
    is_bundle,
    unit_price,
    variant,
    quantity,
  }) => {
    if (is_bundle) {
      for (const id of ids) {
        removeLineItem(id)
      }
    } else {
      trackRemoveFromCart(cart, variant.product, variant, quantity, unit_price)

      removeLineItem(id)
    }
  }

  /**
   * Adds gift card to cart.
   */
  const handleAddGiftCard = async (gcCode) => {
    setGcError(false)
    const newCards = cart.gift_cards.map(({ code }) => ({
      code,
    }))

    return update({
      gift_cards: [...newCards, { code: gcCode }],
    }).catch((err) => {
      switch (err.response.status) {
        case 400:
          setGcError(err.response.data.message)
          break
        case 404:
          setGcError(true)
          break
        default:
          break
      }
      throw err
    })
  }

  /**
   * Removes a gift card
   */
  const handleRemoveGiftCard = async (gcCode) => {
    const newCards = cart.gift_cards.filter(({ code }) => code !== gcCode)

    return update({
      gift_cards: newCards,
    })
  }

  /**
   * Adds discount code to cart
   */
  const handleAddCouponCode = (couponCode) => {
    setCcError(false)
    return update({
      discounts: [{ code: couponCode }],
    }).catch((err) => {
      if (err.response.status >= 400 && err.response.status < 500) {
        setCcError(true)
      }
      throw err
    })
  }

  /**
   * Remove discount code from cart
   */
  const handleRemoveCouponCode = (couponCode) => {
    return removeCouponCode(couponCode)
  }

  return (
    <CartDrawerContainer
      visible={visible}
      promotionalVisible={promotionalVisible}
    >
      <CartSummary
        cart={cart}
        freeShippingLimit={freeShippingLimit}
        giftCardError={gcError}
        couponCodeError={ccError}
        onAddCouponCode={handleAddCouponCode}
        onRemoveCouponCode={handleRemoveCouponCode}
        onAddGiftCardCode={handleAddGiftCard}
        onRemoveGiftCard={handleRemoveGiftCard}
        onUpdateQuantity={updateLineItem}
        onRemoveLine={handleRemove}
      />
    </CartDrawerContainer>
  )
}

export default CartDrawer

import React from "react"
import styled from "@emotion/styled"
import { Box } from "@theme-ui/components"

const BaseContainer = styled(Box)`
  display: ${(props) => (props.inline ? "inline-block" : "flex")};
  flex-direction: ${(props) => props?.sx?.flexDirection ?? "column"};

  .label {
    font-size: 10px;
  }
`

const BaseChecker = styled(Box)`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.checked ? props.theme.colors.dark : "transparent"};
  border: ${(props) => props.theme.borders.dark};
  cursor: pointer;
  align-self: ${(props) => (props.align ? props.align : "flex-start")};
`

const CheckBox = ({ onClick, value, label, align, inline, className, sx }) => {
  return (
    <BaseContainer inline={inline} className={className} sx={sx}>
      <BaseChecker
        className="base-checker"
        onClick={onClick}
        checked={value}
        align={align}
      ></BaseChecker>
      <span className="label">{label}</span>
    </BaseContainer>
  )
}

export default CheckBox

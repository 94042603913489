import React from "react"
import { Flex } from "@theme-ui/components"
import styled from "styled-components"
import RichText from "../rich-text"

const PromotionalContainer = styled(Flex)`
  min-height: 30px;
  align-items: center;
  padding: 0 6px;
`

const PromotionalElement = ({ input }) => {
  if (!input) {
    return (
      <PromotionalContainer
        sx={{
          justifyContent: ["center", "flex-end"],
          bg: "white",
          marginBottom: "6px",
          color: "dark",
        }}
      >
        here
      </PromotionalContainer>
    )
  }

  return (
    <PromotionalContainer
      sx={{
        justifyContent: ["center", "flex-end"],
        bg: "white",
        marginBottom: "6px",
        color: "dark",
      }}
    >
      <RichText
        input={input}
        centeredText={true}
        sx={{
          fontSize: "13px",
          padding: "5px",

          p: {
            marginBottom: "0px",
          },
        }}
      />
    </PromotionalContainer>
  )
}

export default PromotionalElement

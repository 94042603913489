import React from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"
import PropTypes from "prop-types"

import Tile from "../tile"
import { ContentfulTypes } from "../../../utils/types"
import AssetWrapper from "../asset-wrapper"
import Carousel from "../carousel"

const CareModuleContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 0px;
  margin: 2em 0;

  video {
    object-fit: cover;
  }
  .items {
    height: 100%;
    flex-wrap: wrap;

    .item {
      flex: 1 1 100%;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    height: 80vh;
    flex: 1;
    > {
      flex: 1;
    }

    .items {
      flex-wrap: nowrap;

      .item {
        flex: 1 1 25%;
      }
    }
  }
`

const CareModule = ({ content, sx }) => {
  return (
    <CareModuleContainer sx={{ color: "dark", ...sx }}>
      <Flex sx={{ flex: 1 }} className="items">
        {content.map((content, index) => {
          if (content.internal.type === ContentfulTypes.ContentfulTile) {
            return (
              <Tile
                key={`${content.id}-${index}`}
                input={{ ...content, hideTitle: true }}
                className="item"
                sx={{ paddingTop: "1em", paddingBottom: ["4em", "1em"] }}
              />
            )
          }
          if (
            content.internal.type === ContentfulTypes.ContentfulAssetWrapper
          ) {
            return (
              <AssetWrapper
                key={content.id}
                asset={content.asset}
                {...content}
                className="item"
              />
            )
          }
          if (content.internal.type === ContentfulTypes.ContentfulCarousel) {
            return (
              <Carousel
                key={content.id}
                assets={content.content}
                className="item"
                sx={{ minHeight: ["80vh", "100%"] }}
                {...content}
              />
            )
          }
        })}
      </Flex>
    </CareModuleContainer>
  )
}

CareModule.propTypes = {
  content: PropTypes.array.isRequired,
}

export default CareModule

import React from "react"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"
import PropTypes from "prop-types"
import Tile from "../tile"
import { ContentfulTypes } from "../../../utils/types"
import ProductHighlight from "../product-highlight"
import useWindowSize from "../../../hooks/useWindowSize"
import Gallery from "../../Page/ContentModules/Gallery"
import { useTranslationContext } from "../../../context/TranslationContext"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"

const TileSectionContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 0px;

  .items {
    flex-wrap: wrap;

    .item {
      flex: 1 1 100%;
      margin: 0px 1px;

      ${(props) => props.mobileColumns === 2 && `flex: 1 1 45%`}
    }
  }

  ${(props) =>
    props.spacing === "Small" &&
    `
    padding: 8px 16px;
    .items {
      gap:8px;
    }
  `}

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    flex: 1;
    > {
      flex: 1;
    }

    .items {
      flex-wrap: nowrap;

      .item:first-of-type {
        margin-left: 0px;
      }

      .item:last-of-type {
        margin-right: 0px;
      }

      .item {
        flex: 1 1 25%;
      }
    }
  }
`

const TileSection = ({ tiles, sx, tileSx, displayTitle, ...rest }) => {
  const { spacing, mobileColumns, isGallery } = rest
  const { translationContext } = useTranslationContext()

  const { width } = useWindowSize()
  const showMobileGallery = width < 576 && isGallery
  if (showMobileGallery) {
    return (
      <Gallery
        input={{
          images: tiles.map((tile) => {
            return {
              key: `tile-${tile.id}`,
              image: tile.backgroundImage,
              title: tile.title,
              id: tile.backgroundImage.id,
              linkUrl: handleReferenceLink({
                reference: tile.reference,
                locale: translationContext.locale,
                linkUrl: tile.linkUrl,
              }),
              hideTitle: tile.hideTitle,
            }
          }),
        }}
      />
    )
  }

  return (
    <TileSectionContainer
      sx={{ color: "dark", ...sx }}
      className={`tilesection`}
      spacing={spacing}
      mobileColumns={mobileColumns}
      isMobileGallery={isGallery}
    >
      {displayTitle && (
        <Text
          sx={{
            fontSize: "19px",
            marginBottom: "24px",
            fontWeight: "normal",
            px: "16px",
          }}
          as="h2"
          className="tilesection-header"
        >
          {displayTitle}
        </Text>
      )}
      <Flex sx={{ flex: 1 }} className="items">
        {tiles.map((tile, index) => {
          if (tile.internal.type === ContentfulTypes.ContentfulTile) {
            return (
              <Tile
                key={`${tile.id}-${index}`}
                input={tile}
                sx={tileSx}
                className="item tile"
                {...rest}
              />
            )
          }
          if (tile.internal.type === ContentfulTypes.ContentfulProduct) {
            return (
              <ProductHighlight
                key={tile.id}
                product={tile}
                sx={tileSx}
                className="item product-highlight"
                {...rest}
              />
            )
          }
        })}
      </Flex>
    </TileSectionContainer>
  )
}

TileSection.propTypes = {
  tiles: PropTypes.array.isRequired,
}

export default TileSection

import { useEffect, useState } from "react"

const useComponentSize = (ref) => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [componentSize, setComponentSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    function handleResize() {
      // Set window width/height to state
      setComponentSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [ref])

  return componentSize
}

export default useComponentSize

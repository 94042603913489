import React, { useState } from "react"
import { Flex, Text } from "theme-ui"
import { Trans, useTranslation } from "react-i18next"

import CloseIcon from "../../../assets/svg/close.svg"
import Medusa from "../../../services/api"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslationContext } from "../../../context/TranslationContext"
import Tile from "../tile"
import InputField from "../input-field"
import Button from "../button"
import { useNotificationContext } from "../../../context/NotificationContext"
import CheckBox from "../checkbox"

export const NewsletterSignup = ({ showImage, onComplete, handleClose }) => {
  const { tiles } = useStaticQuery(graphql`
    query {
      tiles: allContentfulTile(
        filter: { title: { eq: "Subscribe image tile" } }
      ) {
        nodes {
          ...Tile
        }
      }
    }
  `)
  const { translationContext } = useTranslationContext()

  const renderTile = (tile) => {
    return (
      <Tile input={{ ...tile, hideTitle: true }} showProductTitle={false} />
    )
  }

  const tile = tiles.nodes.find(
    (tile) => tile.node_locale === translationContext.locale
  )

  return (
    <Flex
      sx={{
        flex: 1,
        bg: "white",
        color: "dark",
        filter: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.08))",
        flexDirection: ["column", "row"],
        position: "relative",
      }}
    >
      <Flex
        sx={{
          position: "absolute",
          right: 0,
          zIndex: 2,
          margin: ["16px", "16px"],
          justifyContent: "flex-end",
          svg: {
            width: ["28px", "12px"],
            height: ["28px", "12px"],
            cursor: "pointer",
            bg: ["white", "none"],
            borderRadius: ["50%", "none"],
            padding: ["5px", "0px"],
          },
        }}
      >
        <CloseIcon onClick={handleClose} />
      </Flex>
      {(tile.backgroundImage || tile.reference?.imagePacks) && (
        <Flex
          sx={{
            flex: 2,
            maxHeight: ["40vh", "unset"],
            overflow: "hidden",
            ".tile-image-container": { flex: 1 },
          }}
        >
          {renderTile(tile)}
        </Flex>
      )}
      <Flex
        sx={{
          flex: 3,
          bg: "white",
          zIndex: 1,
          maxWidth: "320px",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <Flex sx={{ flexDirection: "column", padding: "8px" }}>
          <NewsletterDescription />
          <NewsletterSignupField onComplete={onComplete} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export const NewsletterInput = ({ showDescription, onComplete }) => {
  return (
    <>
      {showDescription && <NewsletterDescription />}
      <NewsletterSignupField onComplete={onComplete} />
    </>
  )
}

export const NewsletterDescription = () => {
  const { t } = useTranslation()
  return (
    <>
      <Text as="h2" sx={{ fontSize: "19px", fontWeight: "normal", my: "8px" }}>
        {t("keep_in_touch")}
      </Text>
      <Text as="p" sx={{ fontSize: "13px", marginBottom: "16px" }}>
        {t("sign_up_for_newsletter")}
      </Text>{" "}
    </>
  )
}

export const NewsletterSignupField = ({ onComplete }) => {
  const [email, setEmail] = useState()
  const [consent, setConsent] = useState(false)

  const { pushNotification } = useNotificationContext()

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const { t } = useTranslation()

  const handleSignup = () => {
    const payload = {
      email: email,
      ids: {},
    }

    if (window.analytics && window.analytics.user) {
      payload.ids = {
        user_id: window.analytics.user().id(),
        anonymous_id: window.analytics.user().anonymousId(),
      }
    }

    Medusa.newsletter
      .signup(payload)
      .then(({ data }) => {
        // Run complete
        if (onComplete) {
          onComplete()
        }

        if (data.user_exists) {
          pushNotification({
            id: "sign_up_complete",
            body: t("user_is_subscribed"),
            dismiss: {
              duration: 3000,
            },
          })
        } else {
          pushNotification({
            id: "sign_up_complete",
            body: t("success"),
            dismiss: {
              duration: 3000,
            },
          })
        }
      })
      .catch(() => {
        pushNotification({
          id: "sign_up_failed",
          body: t("something_went_wrong_check_input"),
          dismiss: {
            duration: 3000,
          },
        })
      })
  }

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <InputField
        placeholder={t("enter_email")}
        value={email}
        onChange={handleEmailChange}
        sx={{ marginBottom: "8px" }}
      />
      {email?.length > 0 && (
        <>
          <CheckBox
            value={consent}
            onClick={() => setConsent((prev) => !prev)}
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              ".base-checker": {
                display: "block",
                width: "18px",
                height: "18px",
                marginRight: "6px",
              },
              ".label": {
                width: "80%",
                display: "block",
                flexWrap: "wrap",
              },
            }}
            margin=".5rem 0"
            label={
              <Trans as="label" i18nKey="newsletter_notice">
                Yes, sign me up for Tekla Fabrics news & other digital
                communications. I confirm that I am above 15 years old and
                reviewed <a href="/privacy-policy">Privacy Policy</a>. You can
                unsubscribe at any time by clicking the link at the bottom of a
                newsletter.
              </Trans>
            }
          />
          <Button
            disabled={!consent}
            onClick={handleSignup}
            sx={{ fontWeight: "bold", mt: "8px" }}
          >
            {t("subscribe")}
          </Button>
        </>
      )}
    </Flex>
  )
}

import React from "react"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ContentModules from "../../Page/ContentModules/ContentModules"
import { ContentfulTypes } from "../../../utils/types"
import { useTranslationContext } from "../../../context/TranslationContext"

import { formatMoneyAmount, getMinimumPriceOption } from "../../../utils/prices"
import { useStoreContext } from "../../../context/StoreContext"
import { trackRecommendedProduct } from "../../../services/analytics"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"

const TileContainer = styled(Flex)`
  flex: 1;

  color: ${(props) =>
    props.sx?.color ? props.sx.color : props.theme.colors.dark};
`

const TileModule = styled(Flex)`
  flex: 1;
  flex-direction: column;
  height: 100%;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: 0;
  }
`

const TileImageContainer = styled(Flex)`
  flex-direction: column;
`

const TileImage = styled(GatsbyImage)`
  flex: 1;
`

const ImageText = styled(Text)``

const TileLink = styled(Link)`
  flex: 1;
  color: inherit;

  text-decoration: ${(props) =>
    props.sx?.decoration ? props.sx.decoration : "none"};
`

const TileContent = styled(Flex)`
  flex: 1;
  flex-direction: column;

  ${(props) =>
    props.centerContent &&
    `
      justify-content: center;
  
    `}
`

const Tile = ({ input, sx, className, ...rest }) => {
  const { translationContext } = useTranslationContext()
  const { cart } = useStoreContext()

  const {
    backgroundImage,
    hideOnMobile,
    title,
    hideTitle,
    isTextLight,
    showTextOnImage,
    linkUrl,
    content,
    centerContent,
    reference,
    backgroundColor,
    refLocation,
  } = input

  const {
    showProductTitle,
    showProductColor,
    showProductPrice,
    showProductTeaser,
  } = rest

  const centerTypes = [
    ContentfulTypes.ContentfulButton,
    ContentfulTypes.ContentfulRichText,
    ContentfulTypes.ContentfulTabs,
  ]

  const shouldDisplayCenterStyledContent = (type) =>
    centerTypes.some((centerType) => centerType === type)

  const handleContent = () => {
    if (content) {
      return content.map((contentInstance) => {
        if (
          centerContent &&
          shouldDisplayCenterStyledContent(contentInstance.internal.type)
        ) {
          return (
            <CenterStyledContent
              content={contentInstance}
              sx={{ ".rich-text": { padding: "0px" } }}
            />
          )
        }

        return (
          <ContentModules
            key={contentInstance.id}
            allModules={[contentInstance]}
          />
        )
      })
    }

    if (!showTextOnImage) return <Text>{title}</Text>
  }

  const handleBackgroundColor = () => {
    switch (true) {
      case backgroundColor === BackgroundColors.GREY:
        return "careGrey"
      case backgroundColor === BackgroundColors.WHITE:
        return "white"
      default:
        return "transparent"
    }
  }

  const handleRefContent = () => {
    if (reference.internal.type === ContentfulTypes.ContentfulProduct) {
      if (
        !showProductTitle &&
        !showProductTeaser &&
        !showProductPrice &&
        !showProductColor
      ) {
        return
      }
      const referenceColor = reference.color?.name
      const referencePrice = formatMoneyAmount(
        {
          currencyCode: cart.currencyCode,
          amount: getMinimumPriceOption(reference, cart),
        },
        0
      )

      const referenceTitle = reference.bedding_bundle
        ? reference.bedding_bundle?.[0].collection?.[0].name
        : reference.collection?.[0].name ?? reference.title

      const referenceDescription = reference.bedding_bundle
        ? reference.bedding_bundle?.[0].collection?.[0].teaser
        : reference.collection?.[0].teaser

      return (
        <Flex sx={{ flexDirection: "column", paddingBottom: "10px" }}>
          {showProductTitle && (
            <Text
              sx={{ fontSize: "17px", marginTop: "16px" }}
              className="tile-title"
            >
              {referenceTitle}
            </Text>
          )}
          {(showProductColor || showProductPrice || showProductTeaser) && (
            <Flex sx={{ flexDirection: "column" }} className="details">
              {showProductColor && (
                <Text sx={{ fontSize: "13px" }}>{referenceColor}</Text>
              )}
              {showProductPrice && (
                <Text sx={{ fontSize: "13px" }}>{referencePrice}</Text>
              )}
              {showProductTeaser && referenceDescription && (
                <Text
                  sx={{ fontSize: "13px", marginTop: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: referenceDescription?.childMarkdownRemark.html,
                  }}
                />
              )}
            </Flex>
          )}
        </Flex>
      )
    }
  }

  const Module = (
    <TileModule className="tile-module">
      {backgroundImage && !content && (
        <TileImageContainer className="tile-image-container">
          <TileImage
            image={backgroundImage.gatsbyImageData}
            alt=""
            className="tile-image"
          />
        </TileImageContainer>
      )}
      {content && (
        <TileContent className="tile-content" centerContent={centerContent}>
          {handleContent()}
        </TileContent>
      )}
      {!hideTitle && !showProductTitle && title && (
        <Text sx={{ fontSize: "18px", p: "0.8rem" }} className="tile-title">
          {title}
        </Text>
      )}
      {reference && handleRefContent()}
    </TileModule>
  )

  const handleTracking = () => {
    if (reference) {
      if (reference.internal.type === ContentfulTypes.ContentfulProduct) {
        trackRecommendedProduct({
          productId: reference.objectId,
          location: refLocation ?? window.location.pathname,
        })
      }
    }
  }

  return (
    <TileContainer
      hideOnMobile={hideOnMobile}
      sx={{ bg: handleBackgroundColor(), ...sx }}
      className={`tile-container ${className}`}
    >
      {reference || linkUrl ? (
        <TileLink
          to={handleReferenceLink({
            reference: reference,
            locale: translationContext.locale,
            linkUrl: linkUrl,
          })}
          onClick={handleTracking}
          className="tile-link"
        >
          {Module}
        </TileLink>
      ) : (
        Module
      )}
    </TileContainer>
  )
}

const CenterStyledContent = ({ content, sx }) => (
  <ContentModules
    key={content.id}
    allModules={[content]}
    sx={{
      width: ["100%", "450px"],
      overflow: "scroll",
      margin: "0 auto",
      px: ["16px", 0],
      ...sx,
    }}
  />
)

const BackgroundColors = {
  WHITE: "White",
  GREY: "Grey",
}

export default Tile

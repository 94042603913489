import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const ScrollContainer = styled.div`
  position: relative;
  overflow: hidden;
`

export const ImageIndicatorContainer = styled.div`
  display: flex;
`

export const ImageIndicator = styled.div`
  margin: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props =>
    props.active ? props.theme.colors.darkest : props.theme.colors.brownGrey};
`

export const ContentBlock = styled.div`
  display: flex;
  padding: 0.8rem;
  min-height: 82px;
`

export const ImageWrapper = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1px;
  height: 100%;
  width: 100vw;
  grid-template-columns: repeat(${props => props.count}, 100vw);
  overflow: auto;
`

export const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

export const NextButton = styled.div`
  position: absolute;

  top: 0;
  ${props => (props.prev ? "left" : "right")}: 0;

  height: 100%;
  width: 50%;
  z-index: 100;
`

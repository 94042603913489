import React, { useContext } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { Flex, Text } from "theme-ui"
import Image from "../image"

import { formatMoneyAmount, getMinimumPriceOption } from "../../../utils/prices"
import { useStoreContext } from "../../../context/StoreContext"
import { PersonalizationContext } from "../../../context/personalization-context"
import { ContentfulTypes } from "../../../utils/types"
import { useTranslationContext } from "../../../context/TranslationContext"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"

const ProductTile = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  color: ${(props) => props.theme.colors.text};
`

const ProductImgContainer = styled.div`
  display: block;
  flex-direction: column;
  flex: 0 1 60%;

  .default,
  .hover {
    cursor: pointer;
    flex: 1;
  }

  .hover {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      .hover {
        display: block;
      }

      .default {
        display: none;
      }
    }
  }
`

const ProductHighlight = ({
  product,
  collectionPath,
  onProductClick,
  className,
  ...rest
}) => {
  const { gender } = useContext(PersonalizationContext)
  const { translationContext } = useTranslationContext()
  const { cart } = useStoreContext()
  if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
    product = product.duvetProduct
  }

  const {
    showProductTeaser,
    showProductColor,
    showProductPrice,
    showProductTitle,
  } = rest

  let imagePack = product.imagePacks.find((pack) => {
    if (product.imagePacks.length > 1 && pack.isFemale !== null) {
      return (gender === "female") === pack.isFemale
    } else {
      return true
    }
  })

  const thumbnail = imagePack.featuredImage
  const alternate = imagePack.alternateImage
  const slug = product.handle
  const color = product.color?.name
  const price = formatMoneyAmount(
    {
      currencyCode: cart.currencyCode,
      amount: getMinimumPriceOption(product, cart),
    },
    0
  )
  const title = product.collection?.[0].name ?? product.title
  const description = product.collection?.[0].teaser

  const alternativeSlug = handleReferenceLink({
    reference: product,
    locale: translationContext.locale,
  })

  return (
    <ProductTile
      onClick={() => onProductClick && onProductClick(product)}
      to={collectionPath ? `${collectionPath}/${slug}` : alternativeSlug}
      key={product.id}
      className={className}
    >
      <ProductImgContainer>
        <Image className="default" image={thumbnail.gatsbyImageData} alt="" />
        <Image className="hover" image={alternate.gatsbyImageData} alt="" />
      </ProductImgContainer>

      {(showProductTitle ||
        showProductColor ||
        showProductPrice ||
        showProductTeaser) && (
        <Flex sx={{ flexDirection: "column", paddingBottom: "10px" }}>
          {showProductTitle && (
            <Text sx={{ fontSize: "17px", marginTop: "16px" }}>{title}</Text>
          )}
          <Flex sx={{ flexDirection: "column" }} className="details">
            {showProductColor && <Text sx={{ fontSize: "13px" }}>{color}</Text>}
            {showProductPrice && <Text sx={{ fontSize: "13px" }}>{price}</Text>}
            {showProductTeaser && description && (
              <Text
                sx={{ fontSize: "13px", marginTop: "16px" }}
                dangerouslySetInnerHTML={{
                  __html: description?.childMarkdownRemark.html,
                }}
              />
            )}
          </Flex>
        </Flex>
      )}
    </ProductTile>
  )
}

export default ProductHighlight
